import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material'
import React, { useState } from 'react'

interface CompanyInfo {
  company_info_id: string
  company_name: string
  location: string
  years_in_business: string
  approx_credit_score: string
  phone_number: string
  email: string
  num_of_trucks_making_payment_on: string
  monthly_truck_payment: string
}

interface ProfileInfoDialogProps {
  companyInfo: CompanyInfo | null
  isOpen: boolean
  onClose: () => void
}

const ProfileInfoDialog: React.FC<ProfileInfoDialogProps> = ({ companyInfo, isOpen, onClose }) => {
  const [companyInfoFormData, setCompanyInfoFormData] = useState({
    companyInfoId: '',
    companyName: '',
    location: '',
    yearsInBusiness: '',
    approxCreditScore: '',
    phoneNumber: '',
    email: '',
    numOfTrucksMakingPaymentOn: '',
    monthlyTruckPayment: '',
  })

  const handleChange = (e: { target: { name: string; value: string } }) => {
    setCompanyInfoFormData({ ...companyInfoFormData, [e.target.name]: e.target.value })
  }

  const handleSubmit = () => {}

  return (
    <Dialog fullWidth open={isOpen} onClose={onClose}>
      <DialogTitle>Profile Info</DialogTitle>
      <DialogContent>
        <Box component='form' onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id='companyName'
                label='Company Name'
                name='companyName'
                value={companyInfo?.company_name || companyInfoFormData.companyName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id='location'
                label='Location'
                name='location'
                value={companyInfo?.location || companyInfoFormData.location}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id='yearsInBusiness'
                label='Years in Business'
                name='yearsInBusiness'
                value={companyInfo?.years_in_business || companyInfoFormData.yearsInBusiness}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id='phoneNumber'
                label='Phone Number'
                name='phoneNumber'
                value={companyInfo?.phone_number || companyInfoFormData.phoneNumber}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id='email'
                label='Email'
                name='email'
                value={companyInfo?.email || companyInfoFormData.email}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button type='submit' onClick={handleSubmit}>
          Save
        </Button>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ProfileInfoDialog
