import React from 'react'
import { Box, Container } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { logoNoBackground, authBackground } from 'assets'
import Footer from 'components/Footer'
import { alpha } from '@mui/material/styles'

interface Props {
  children: React.ReactNode
}

const AuthLayout = ({ children }: Props): JSX.Element => {
  const theme = useTheme()

  return (
    <Container
      maxWidth={false}
      sx={{
        backgroundImage: `url(${authBackground})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top center',
        backgroundAttachment: 'fixed',
        height: '100vh',
        width: '100vw',
        position: 'absolute',
        // top: 100,
        // left: 0,
        // zIndex: -1
      }}
    >
      <Container
        sx={{
          backgroundColor: alpha(theme.palette.primary.light, 0.9), // Using theme's primary light color with 90% opacity
          borderRadius: theme.shape.borderRadius,
          paddingBottom: theme.spacing(2.5),
        }}
        component='main'
        maxWidth='xs'
      >
        <Box
          sx={{
            marginTop: theme.spacing(10),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box
            component='img'
            src={logoNoBackground}
            sx={{
              height: '100px',
              width: '100px',
              // backgroundColor: 'white',
              marginTop: theme.spacing(2),
              padding: theme.spacing(1),
              borderRadius: theme.shape.borderRadius,
            }}
          />
          <main>{children}</main>
        </Box>
      </Container>

      <Footer />
    </Container>
  )
}
export default AuthLayout
