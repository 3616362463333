import { addDoc, collection, doc, setDoc } from '@firebase/firestore'
import { Button, Typography } from '@mui/material'
import { useAuth } from 'contexts/AuthContext'
import { getJwtToken } from 'core/api'
import { db } from 'core/config/firebase'
import { generateMD5Hash } from 'core/utils/generateMD5Hash'
import jwtDecode from 'jwt-decode'
import MainLayout from 'layouts/MainLayout'
import React from 'react'
import { ApplicationTable } from './components'

const ClientHome = (): JSX.Element => {
  const { userInfo } = useAuth()


  const jwtToken = getJwtToken()
  // @ts-expect-error - jwtDecode returns an object with a user_id property
  const uid = jwtDecode(jwtToken).user_id
  const startPhotoUpload = () => {
    console.log('start photo upload')

    const encodedToken = btoa(`${uid},${jwtToken}` as string)
    console.log(uid)
    const tokenMD5Hash = generateMD5Hash(encodedToken)

    const docRef = doc(collection(db, 'photolinks'), tokenMD5Hash)

    setDoc(docRef, {
      link: encodedToken,
    }).then(() => console.log('Document written with ID: ', docRef.id))

    addDoc(collection(db, 'messages'), {
      to: '5712911193',
      body: `http://192.168.50.226:3000/token/${tokenMD5Hash}`,
    }).then(() => console.log('Queued message for delivery!'))
  }

  return (
    <MainLayout>
      <Typography variant='h3' gutterBottom>
        Hello, {userInfo?.first_name}! Welcome to the the Optic Truck Works Portal.
      </Typography>

      <Button onClick={startPhotoUpload}>Upload Photo</Button>

      <Typography variant='h5' marginTop={5} marginBottom={1} gutterBottom>
        Here are your applications:
      </Typography>

      <ApplicationTable />
    </MainLayout>
  )
}

export default ClientHome
