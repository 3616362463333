/* eslint-disable */

/* @Antsega do u want to type this */

import React from 'react'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'

interface SizeDropdownProps {
  index: number
  size: string
  setSize: any
}

const SizeDropdown = ({ index, size, setSize }: SizeDropdownProps) => {
  const handleChange = (event: { target: { value: any } }) => {
    setSize(index, 'size', event.target.value)
  }

  return (
    <FormControl sx={{ width: '100%' }}>
      <InputLabel htmlFor={`grouped-native-select-${index}`}>Size</InputLabel>
      <Select
        native
        value={size}
        onChange={handleChange}
        inputProps={{
          name: 'size',
          id: `grouped-native-select-${index}`, // Correctly adjusted id
        }}
        label='Grouping'
        sx={{ maxHeight: 200, overflowY: 'auto' }}
      >
        <option aria-label='None' value='' />
        <optgroup label='Cutaways'>
          <option value={`10' Cutaway`}>10' Cutaway</option>
          <option value={`12' Cutaway`}>12' Cutaway</option>
          <option value={`14' Cutaway`}>14' Cutaway</option>
          <option value={`15' Cutaway`}>15' Cutaway</option>
          <option value={`16' Cutaway`}>16' Cutaway</option>
          <option value={`17' Cutaway`}>17' Cutaway</option>
          <option value={`18' Cutaway`}>18' Cutaway</option>
        </optgroup>
        <optgroup label='Box trucks'>
          <option value={`20' Box truck`}>20' Box truck</option>
          <option value={`22' Box truck`}>22' Box truck</option>
          <option value={`24' Box truck`}>24' Box truck</option>
          <option value={`26' Box truck`}>26' Box truck</option>
        </optgroup>
        <optgroup label='Step vans'>
          <option value={`P500 Step van`}>P500 Step van</option>
          <option value={`P700 Step van`}>P700 Step van</option>
          <option value={`P900 Step van`}>P900 Step van</option>
          <option value={`P1000 Step van`}>P1000 Step van</option>
          <option value={`P1100 Step van`}>P1100 Step van</option>
          <option value={`P1200 Step van`}>P1200 Step van</option>
        </optgroup>
        <option value={'Cargo'}>Cargo</option>
      </Select>
    </FormControl>
  )
}

export default SizeDropdown
