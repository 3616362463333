import { getFirestore, collection, getDocs } from '@firebase/firestore';

const db = getFirestore();

export const getBanksIdAndNames = async () => {
  const banksCollection = collection(db, 'banks');
  const banksSnapshot = await getDocs(banksCollection);
  const banksData: { [id: string]: string } = {};

  banksSnapshot.forEach(doc => {
    const data = doc.data();
    if (data.name) {
      banksData[doc.id] = data.name;
    }
  });

  return banksData;
};
