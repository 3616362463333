import React, { useState } from 'react'
import MainLayout from 'layouts/MainLayout'
import { TextField, Button, Typography, Grid, RadioGroup, Radio, FormControlLabel } from '@mui/material'
import { PageTitle } from 'components/PageTitle'

const DepreciationCalculator = () => {
  const [age, setAge] = useState('')
  const [newPrice, setNewPrice] = useState('')
  const [mileage, setMileage] = useState('')
  const [depreciation, setDepreciation] = useState('')
  const [selectedFormula, setSelectedFormula] = useState('a')

  const calculateDepreciation = (event: { preventDefault: () => void }) => {
    event.preventDefault()

    if (selectedFormula === 'a') {
      const yearOneDepreciation = 0.15
      const yearTwoDepreciation = 0.1
      const yearThreePlusDepreciation = 0.05

      const ageNum = parseInt(age)
      const newPriceNum = parseFloat(newPrice)
      const mileageNum = parseInt(mileage)

      let currentValue = newPriceNum
      for (let year = 1; year <= ageNum; year++) {
        if (year === 1) {
          currentValue -= currentValue * yearOneDepreciation
        } else if (year === 2) {
          currentValue -= currentValue * yearTwoDepreciation
        } else {
          currentValue -= currentValue * yearThreePlusDepreciation
        }
      }

      const mileageDepreciation = mileageNum * 0.3
      const totalDepreciation = currentValue - mileageDepreciation

      setDepreciation(totalDepreciation.toFixed(2))
    } else if (selectedFormula === 'b') {
      const yearOneDepreciation = 0.0
      const yearTwoDepreciation = 0.15
      const yearThreeDepreciation = 0.1
      // const yearFourPlusDepreciation = 0.05;

      const ageNum = parseInt(age)
      const newPriceNum = parseFloat(newPrice)
      const mileageNum = parseInt(mileage)

      let currentValue = newPriceNum
      for (let year = 1; year <= ageNum; year++) {
        if (year === 1) {
          currentValue -= currentValue * yearOneDepreciation
        } else if (year === 2) {
          currentValue -= currentValue * yearTwoDepreciation
        } else if (year === 3) {
          currentValue -= currentValue * yearThreeDepreciation
        }
      }

      const mileageDepreciation = mileageNum * 0.3
      const totalDepreciation = currentValue - mileageDepreciation

      setDepreciation(totalDepreciation.toFixed(2))
    }
  }

  return (
    <MainLayout>
      <Grid container justifyContent='center' alignItems='center'>
        <Grid item xs={14} sm={8} md={6} style={{ textAlign: 'center' }}>
          <form onSubmit={calculateDepreciation}>
            {/* <Typography variant="h4" style={{ marginBottom: '20px' }}>Depreciation Calculator</Typography> */}

            <PageTitle
              title='Depreciation Calculator'
              subtitle='Calculate the depreciation of a vehicle based on age, price, and mileage.'
              bulletPoints={[
                'Enter the age of the vehicle in years.',
                'Enter the price of the vehicle when it was new.',
                'Enter the current mileage of the vehicle.',
                'Select the depreciation formula to use.',
              ]}
            />

            <RadioGroup
              row
              aria-label='depreciation-formula'
              name='depreciation-formula'
              value={selectedFormula}
              onChange={e => setSelectedFormula(e.target.value)}
              style={{ margin: '20px 0', position: 'inherit' }}
            >
              <FormControlLabel value='a' control={<Radio />} label='Formula A' />
              <FormControlLabel value='b' control={<Radio />} label='Formula B' />
            </RadioGroup>

            <TextField
              label='Age of Vehicle (Years)'
              variant='outlined'
              type='number'
              value={age}
              onChange={e => setAge(e.target.value)}
              fullWidth
              style={{ margin: '10px 0' }}
              // test commit
            />
            <TextField
              label='Price of New Vehicle'
              variant='outlined'
              type='number'
              value={newPrice}
              onChange={e => setNewPrice(e.target.value)}
              fullWidth
              style={{ margin: '10px 0' }}
            />
            <TextField
              label='Mileage'
              variant='outlined'
              type='number'
              value={mileage}
              onChange={e => setMileage(e.target.value)}
              fullWidth
              style={{ margin: '10px 0' }}
            />
            <Button type='submit' variant='contained' color='primary'>
              Calculate
            </Button>
            {depreciation && (
              <Typography style={{ marginTop: '20px' }}>Your vehicle is worth this: ${depreciation}</Typography>
            )}
          </form>
        </Grid>
      </Grid>
    </MainLayout>
  )
}

export default DepreciationCalculator
