import React, { useState } from 'react'
import { MainLayout } from 'layouts'
import { useAuth } from 'contexts/AuthContext'
import { NotFound } from 'components'
import { Button, Typography } from '@mui/material'
import CreateLeadDialog from './components/CreateLeadDialog'
import LeadTable from './components/LeadTable'
import W9Form from './components/W9Form'

const AffiliateDashboard = () => {
  const { userInfo } = useAuth()
  const [isDialogOpen, setDialogOpen] = useState(false)

  if (userInfo?.affiliate_id === null) {
    return <NotFound />
  }

  const handleOpenDialog = () => setDialogOpen(true)
  const handleCloseDialog = () => setDialogOpen(false)

  return (
    <MainLayout>
      {userInfo?.status === 'Pending' && (
        <Typography component='p' gutterBottom>
          Your Affiliate Application is Pending Approval
        </Typography>
      )}

      {userInfo?.status === 'Suspend' && (
        <Typography component='p' gutterBottom>
          Your Affiliate Access has been Suspended
        </Typography>
      )}

      {userInfo?.status === 'Approved' && (
        <>
          <h1>Affiliate Dashboard</h1>

          {!userInfo?.w9_document_uuid ? (
            <>
              <Typography component='p' gutterBottom>
                You must complete the W9 form before you can begin submitting leads.
              </Typography>
              <W9Form />
            </>
          ) : (
            <>
              <Button onClick={handleOpenDialog}>Create Lead</Button>
              <CreateLeadDialog open={isDialogOpen} onClose={handleCloseDialog} />
              <LeadTable />
            </>
          )}
        </>
      )}
    </MainLayout>
  )
}

export default AffiliateDashboard
