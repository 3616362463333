import { getHeaders, functionUrls } from 'core/api'

export const deleteHubspotContact = async (contactId: string) => {
  const functionUrl: string = functionUrls.deleteHubspotContact

  try {
    const res = await fetch(functionUrl, {
      method: 'DELETE',
      headers: getHeaders(),
      body: JSON.stringify({ contactId }),
    })

    if (res.status === 200) {
      const data = await res.json()
      return data
    } else {
      const errorText = await res.text()
      throw new Error(`Request failed with status ${res.status}: ${errorText}`)
    }
  } catch (error) {
    console.error('Error in deleteHubspotContact:', error)
    throw error
  }
}
