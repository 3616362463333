import { httpCodes } from 'core/api/httpCodes'
import { getHeaders, functionUrls } from 'core/api'

export const createFirebaseUser = async (userData: CreateUserType) => {
  const res = await fetch(functionUrls.createFirebaseUser, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(userData),
  })
  if (res.status === 200) {
    return 'Created'
  } else {
    const status = res.status
    const message = httpCodes[status] || 'An error occurred, please contact Chase.'
    return message
  }
}
