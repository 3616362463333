/* eslint-disable */
import { db } from 'core/config/firebase'
import { doc, getDoc } from 'firebase/firestore'

export const downloadShareableInventoryCsv = async (masterInventoryIds: string[]) => {
  // Fetch inventory details
  const inventoryData = await Promise.all(
    masterInventoryIds.map(async (id) => {
      const inventoryRef = doc(db, 'master_inventory', id)
      const inventoryDoc = await getDoc(inventoryRef)
      if (inventoryDoc.exists()) {
        const data = inventoryDoc.data()
        const { profit, ...rest } = data
        return { id, ...rest }
      } else {
        return null
      }
    }),
  )

  // Filter out any null entries (in case some docs don't exist)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const filteredData = inventoryData.filter(item => item !== null) as Array<{ [key: string]: any }>

  // Check if filteredData is not empty before accessing its first item
  if (filteredData.length === 0) {
    throw new Error("No valid inventory data found.")
  }

  // Convert JSON data to CSV
  const headers = Object.keys(filteredData[0]).join(',')  // Use keys from the first object as CSV headers
  const rows = filteredData.map(item => 
    Object.values(item).map(value => `"${value}"`).join(',')
  )

  const csvContent = [headers, ...rows].join('\n')

  // Create a blob and trigger download
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
  const link = document.createElement('a')
  const url = URL.createObjectURL(blob)
  link.href = url
  link.setAttribute('download', 'Inventory.csv')
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  URL.revokeObjectURL(url)
}
