import { db } from 'core/config/firebase'
import { doc, getDoc, updateDoc } from 'firebase/firestore'

export type PhotoPosition = 'main' | 'front' | 'back' | 'left' | 'right' | 'interior' | 'engine' | 'trunk' | 'other'

export const updatePhotoPosition = async (inventoryId: string, photoName: string, position: PhotoPosition) => {
  const inventoryDocRef = doc(db, 'master_inventory', inventoryId)

  const docSnap = await getDoc(inventoryDocRef)

  if (!docSnap.exists()) {
    console.error('No such document!')
    return
  }

  const inventoryDocData = docSnap.data()

  const photos = {
    ...inventoryDocData.photos,
    [position]: photoName,
  }

  await updateDoc(inventoryDocRef, {
    photos: photos,
  })
}
