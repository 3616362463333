import { deleteDoc, doc } from '@firebase/firestore'
import { db } from 'core/config/firebase'

export const deleteUserFirestoreProfile = async (userId: string) => {
  const userRef = doc(db, `users/${userId}`)

  try {
    await deleteDoc(userRef)
    return true
  } catch (error) {
    console.error('Error deleting user profile:', error)
    return false
  }
}
