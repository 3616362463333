/* eslint-disable */

import { functionUrls } from '../functionUrls'
import { getHeaders } from '../getHeaders'


interface GetPhotoUploadLinkProps {
  email: string
  vehicleData: any
}


export const getPhotoUploadLink = async (props: GetPhotoUploadLinkProps): Promise<string> => {


  const { email, vehicleData } = props

  try {
    const response = await fetch(functionUrls.generateSignInLink, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify({ email: email, vehicleData: vehicleData}),
    })

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`)
    }

    const data = await response.json()
    return data.link
  } catch (error) {
    console.error('Error in send photo upload link:', error)
    throw error
  }
}
