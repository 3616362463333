import { DocumentData, QueryFieldFilterConstraint, QueryOrderByConstraint, WhereFilterOp } from '@firebase/firestore'
import { Box } from '@mui/material'
import {
  DataGrid,
  GridColDef,
  GridColumnVisibilityModel,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridSlots,
  useGridApiRef,
} from '@mui/x-data-grid'
import { useFirestoreCollectionMutation, useFirestoreQuery } from '@react-query-firebase/firestore'
import { useSnackbar } from 'contexts/snackBarContext'
import { db } from 'core/config/firebase'
import { collection, deleteDoc, doc, getDoc, orderBy, query, updateDoc, where } from 'firebase/firestore'
import React, { useMemo, useState } from 'react'
import { useMutation } from 'react-query'
import DataGridToolBar, { SelectionButtonT } from './DataGridToolBar'
import DeleteConfirmationDialog from './DeleteConfirmationDialog'

export type ColumnFunctionsType = {
  [key: string]: (id: string, value: string) => void | Promise<void>
}

interface DataGridFirestoreCRUDProps {
  collectionName: string
  columns: GridColDef[]
  hiddenColumns?: string[]
  filters?: [string, WhereFilterOp, string | number][]
  orderByColumn?: string
  staticValues?: string[]
  rowSize?: number
  search?: boolean
  create?: boolean
  editable: boolean
  doubleClickEdit?: boolean
  deleteable?: boolean
  multiDeleteable?: boolean
  onDoubleClick?: (id: string) => void
  onDeleteClick?: (id: string) => void
  customToolbarSelectionButtons?: SelectionButtonT[]
  customColumnSaveFunctions?: ColumnFunctionsType
  viewOnlyMode?: boolean
}

const DataGridFirestoreCRUD: React.FC<DataGridFirestoreCRUDProps> = ({
  collectionName,
  hiddenColumns,
  columns,
  filters,
  orderByColumn,
  search = true,
  create = true,
  editable = true,
  deleteable = true,
  multiDeleteable = false,
  onDoubleClick,
  onDeleteClick,
  customToolbarSelectionButtons,
  customColumnSaveFunctions,
  viewOnlyMode
}) => {
  const collectionRef = collection(db, collectionName)
  const { showSnackbar } = useSnackbar()
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>(
    Object.fromEntries((hiddenColumns ?? []).map(columnName => [columnName, false])),
  )
  const apiRef = useGridApiRef()

  const generateNewRow = useMemo(() => {
    const newRow: DocumentData = {}
    columns.forEach(column => {
      newRow[column.field] = ''
    })
    return newRow
  }, columns)

  const queryKey = [collectionName, filters, orderByColumn]
  const getFinalRef = useMemo(() => {
    const queryConstraints: (QueryFieldFilterConstraint | QueryOrderByConstraint)[] = []
    filters && filters.forEach(filter => queryConstraints.push(where(...filter)))
    orderByColumn && queryConstraints.push(orderBy(orderByColumn))
    const finalRef = query(collectionRef, ...queryConstraints)
    return finalRef
  }, queryKey)

  const { data: rows, isLoading } = useFirestoreQuery(
    queryKey,
    getFinalRef,
    {
      subscribe: true,
    },
    {
      select: snapshot => {
        if (!snapshot || !snapshot.docs) return []
        return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
      },
      enabled: !!getFinalRef,
    },
  )

  // CREATE

  const handleCreateRow = () => {
    // TODO: make sure the selected values are not empty string
    const newRow = generateNewRow
    addFirebaseDocument(newRow)
  }

  const { mutate: addFirebaseDocument } = useFirestoreCollectionMutation(collectionRef, {
    onError: error => {
      showSnackbar('Adding row failed', 'error')
      console.error(error)
    },
  })

  // UPDATE

  const getUpdatedFirebaseData = async ({ id, ...data }: GridRowModel) => {
    const docRef = doc(collectionRef, id)
    const existingDoc = await getDoc(docRef)
    const existingData: DocumentData = existingDoc.exists() ? existingDoc.data() : {}
    Object.keys(data).forEach(columnName => {
      if (data[columnName] === undefined) {
        delete data[columnName]
      } else if (
        data[columnName] !== existingData[columnName] &&
        customColumnSaveFunctions &&
        customColumnSaveFunctions[columnName]
      ) {
        customColumnSaveFunctions[columnName](id, data[columnName])
      }
    })
    return { ...data, id }
  }

  const { mutate: handleUpdateRow } = useMutation(
    'updateRow',
    async (row: GridRowModel) => {
      const updateData = await getUpdatedFirebaseData(row)
      const docRef = doc(collectionRef, row.id)
      await updateDoc(docRef, updateData)
      return { ...updateData, id: row.id }
    },
    {
      onError: error => {
        showSnackbar('Updating row failed', 'error')
        console.error('Error updating project in Firestore:', error)
      },
    },
  )

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true
    }
  }

  // DELETE

  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false)
  const [deleteMessage, setConfirmDeleteMessage] = useState<string>('')

  //  checkbox selected rows obj
  const [selectedRows, setSelectedRows] = useState<GridRowId[]>([])

  const { mutate: deleteFirebaseDocs } = useMutation(
    async () => {
      selectedRows.forEach(async id => {
        if (onDeleteClick) {
          onDeleteClick(id.toString())
        }
        await deleteDoc(doc(db, collectionName, id.toString()))
      })
    },
    {
      onError: error => {
        showSnackbar(`Deleting row${selectedRows.length === 1 ? '' : 's'} failed`, 'error')
        console.error(`Error deleting document${selectedRows.length === 1 ? '' : 's'}:`, error)
      },
      onSettled: () => {
        setOpenDeleteConfirmation(false)
      },
      onSuccess: () => {
        setSelectedRows([])
      },
    },
  )

  const handleCancelDelete = () => {
    setOpenDeleteConfirmation(false)
  }

  const handleCellDoubleClick: GridEventListener<'cellDoubleClick'> = params => {
    onDoubleClick && onDoubleClick(String(params.id))
  }

  const handleDeleteRows = (selectedRowIds: GridRowId[]) => {
    setConfirmDeleteMessage(
      `Are you sure you want to delete ${selectedRowIds.length} row${selectedRowIds.length === 1 ? '' : 's'}?`,
    )
    setOpenDeleteConfirmation(true)
  }

  const handleColumnVisibilityChange = (newModel: GridColumnVisibilityModel) => {
    setColumnVisibilityModel(newModel)
  }

  const customColumns: GridColDef[] = columns.map(column => ({
    ...column,
    editable: editable && column.editable !== false,
  }))

  const handleSave = () => {
    const rowsInEditMode = apiRef.current
      .getAllRowIds()
      .filter(id => apiRef.current.getRowMode(id) === GridRowModes.Edit)

    rowsInEditMode.forEach(selectedId => {
      apiRef.current.stopRowEditMode({ id: selectedId })
    })
  }

  const renderDataGrid = React.useMemo(() => {
    return (
      <Box sx={{ height: '70vh', width: '100%' }}>
        <DeleteConfirmationDialog
          open={openDeleteConfirmation}
          title='Confirm Delete'
          content={deleteMessage}
          onConfirm={() => deleteFirebaseDocs()}
          onCancel={handleCancelDelete}
        />

        <DataGrid
          initialState={{
            columns: {
              columnVisibilityModel: columnVisibilityModel,
            },
          }}
          onColumnVisibilityModelChange={handleColumnVisibilityChange}
          apiRef={apiRef}
          checkboxSelection={multiDeleteable}
          onCellDoubleClick={handleCellDoubleClick}
          rows={isLoading ? [] : rows}
          autoHeight
          columns={customColumns.map((column, colNum) => ({
            ...column,
            flex: 1,
            minWidth: colNum === customColumns.length - 1 ? 250 : 100,
          }))}
          editMode='row'
          onRowEditStop={handleRowEditStop}
          isCellEditable={() => !viewOnlyMode}
          processRowUpdate={updatedRow => {
            handleUpdateRow(updatedRow)
            return updatedRow
          }}
          onProcessRowUpdateError={error => console.error('Update Row error:', error)}
          rowSelectionModel={selectedRows}
          onRowSelectionModelChange={newSelectionModel => {
            setSelectedRows([...newSelectionModel])
          }}
          hideFooterSelectedRowCount
          sx={{
            '& .row-to-delete': {
              backgroundColor: '#ffcccc',
            },
            '& .MuiDataGrid-cell': {
              display: 'flex',
              alignItems: 'center',
              fontSize: '0.8rem',
              padding: '12px',
              wordWrap: 'break-word',
            },
            '& .MuiDataGrid-columnHeaders': {
              fontSize: '0.8rem',
              padding: '12px',
            },
            '& .MuiDataGrid-columnHeader': {
              whiteSpace: 'normal',
              wordWrap: 'break-word',
            },
          }}
          slots={{
            toolbar: DataGridToolBar as GridSlots['toolbar'],
          }}
          slotProps={{
            toolbar: {
              collectionName,
              onDeleteRows: handleDeleteRows,
              onCreateRow: handleCreateRow,
              onSaveRows: handleSave,
              search,
              create,
              showQuickFilter: true,
              deleteable: deleteable,
              multiDeleteable: multiDeleteable,
              selectedRows: selectedRows,
              customToolbarSelectionButtons,
            },
          }}
          pageSizeOptions={[25, 50, 100]}
        />
      </Box>
    )
  }, [rows, selectedRows, openDeleteConfirmation, deleteMessage])

  return <>{renderDataGrid}</>
}

export default DataGridFirestoreCRUD
