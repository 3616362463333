import { db } from 'core/config/firebase'
import { doc, getDoc } from 'firebase/firestore'
import { getDownloadURL, getStorage, listAll, ref } from 'firebase/storage'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'

export const downloadShareableInventoryPdf = async (masterInventoryIds: string[]) => {
  const storage = getStorage()

  // Fetch inventory details and images URLs
  const inventoryData = await Promise.all(
    masterInventoryIds.map(async (id) => {
      const inventoryRef = doc(db, 'master_inventory', id)
      const inventoryDoc = await getDoc(inventoryRef)
      if (inventoryDoc.exists()) {
        const data = inventoryDoc.data()

        //  TODO: Remove price here? 
        const imageRefs = await listAll(ref(storage, `master_inventory/${id}`))
        const imageUrls = await Promise.all(imageRefs.items.map(imageRef => getDownloadURL(imageRef)))
        return { ...data, imageUrls }
      } else {
        return null
      }
    }),
  )

  // Filter out any null entries (in case some docs don't exist)
  const filteredData = inventoryData.filter(item => item !== null)

  // Create a new PDF document
  const pdf = new jsPDF()

  const loadImage = (url: string) => {
    return new Promise<HTMLImageElement>((resolve, reject) => {
      const img = new Image()
      img.src = url
      img.onload = () => resolve(img)
      img.onerror = reject
    })
  }

  for (const [index, item] of filteredData.entries()) {
    if (!item) continue
    if (index !== 0) pdf.addPage()
    pdf.setFontSize(12)

    // Prepare the table data
    const tableData = Object.entries(item)
      .filter(([key]) => key !== 'imageUrls' && key !== 'profit')
      .map(([key, value]) => [key, value])

    // Add table to the PDF
    autoTable(pdf, {
      head: [['Key', 'Value']],
      body: tableData,
      startY: 10,
      theme: 'striped',
    })

    // Calculate the starting height for images
    const imagesStartHeight = pdf.lastAutoTable.finalY + 10

    let currentHeight = imagesStartHeight
    let currentX = 10
    const imageWidth = 50
    const imageHeight = 50
    const margin = 10
    const imagesPerRow = 3

    // Add images to the PDF
    const imagePromises = item.imageUrls.map(async (imageUrl: string, index: number) => {
      const img = await loadImage(imageUrl)
      if (currentHeight + imageHeight > pdf.internal.pageSize.height) {
        pdf.addPage()
        currentHeight = 10
        currentX = 10
      }
      pdf.addImage(img, 'JPEG', currentX, currentHeight, imageWidth, imageHeight)
      currentX += imageWidth + margin
      if ((index + 1) % imagesPerRow === 0) {
        currentX = 10
        currentHeight += imageHeight + margin
      }
    })

    await Promise.all(imagePromises)
  }

  pdf.save('Inventory.pdf')
}
