import {
  Auth,
  User,
  createUserWithEmailAndPassword,
  deleteUser as gcpDeleteUser,
  sendEmailVerification,
} from 'firebase/auth'

import { createUser, deleteUserFirestoreProfile, sendRequestedUserEmail } from 'core/api/users'

export const signUpAndSendEmail = async (credentials: SignUpCredentials, auth: Auth) => {
  try {
    const created_auth_user = await createUserWithEmailAndPassword(auth, credentials.email, credentials.password)

    if (!created_auth_user) {
      throw new Error('Failed to create auth user')
    }

    const newUser = await createUser(auth.currentUser, {
      email: credentials.email,
      first_name: credentials.firstName,
      last_name: credentials.lastName,
      user_type: 'non-admin',
      email_verified: false,
      is_active: false,
    })

    if (!newUser) {
      throw new Error('Failed to create Firestore user')
    }
    auth.currentUser && (await sendEmailVerification(auth.currentUser))
    try {
      sendRequestedUserEmail(credentials.email, credentials.firstName, credentials.lastName)
    } catch (error) {
      console.error('Failed to send email to user', error)
    }
    return true
  } catch (error) {
    if (auth.currentUser) {
      await gcpDeleteUser(auth.currentUser as User)
      await deleteUserFirestoreProfile(auth.currentUser.uid)
    }
    console.error(error)
    return false
  }
}
