import { deleteUserFirestoreProfile } from 'core/api/users'
import { getHeaders, functionUrls } from 'core/api'

export const deleteFirebaseUser = async (userId: string) => {
  // This will delete a user along with all objects associated with that user via UID via the deleteFirebaseUser Cloud Function

  try {
    const res = await fetch(functionUrls.deleteFirebaseUser, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify({ userId }),
    })

    if (!res.ok) {
      console.error(`Error deleting firebase user ${res.status}`)
      return false
    }
    await deleteUserFirestoreProfile(userId)

    return true
  } catch (error) {
    console.error(error)
    return false
  }
}
