import React, { useState } from 'react'
import { Grid, TextField, Box, Typography, Button, CircularProgress, Backdrop } from '@mui/material'
import { createHubspotContact, pullHubspotContacts } from 'core/api/hubspot'

interface CreateUserFormProps {
  handleClose: () => void // state placeholder
  setErrorCreatingContact: React.Dispatch<React.SetStateAction<string | null>> // error catcher
  errorCreatingContact: string | null // error log
}

// functional component
// deconstructs prop
const CreateUserForm: React.FC<CreateUserFormProps> = ({
  handleClose,
  setErrorCreatingContact,
  errorCreatingContact,
}) => {
  const [contactData, setContactData] = useState({
    email: '',
    firstname: '',
    lastname: '',
    website: '',
    company: '',
    phone: '',
    address: '',
    city: '',
    state: '',
    zip: '',
  })
  const [errors, setErrors] = useState({
    email: '',
    phone: '',
    zip: '',
  })
  const [loading, setLoading] = useState(false)
  // onsubmit dialog pop up state

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setContactData(prevState => ({ ...prevState, [name]: value }))

    // Validate fields on change
    switch (name) {
      case 'email':
        setErrors(prevErrors => ({
          ...prevErrors,
          email: validateEmail(value) ? '' : 'Invalid email address',
        }))
        break
      case 'phone':
        setErrors(prevErrors => ({
          ...prevErrors,
          phone: validatePhone(value) ? '' : 'Invalid phone number',
        }))
        break
      case 'zip':
        setErrors(prevErrors => ({
          ...prevErrors,
          zip: validateZip(value) ? '' : 'Invalid zip code',
        }))
        break
      default:
        break
    }
  }

  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return re.test(email)
  }

  const validatePhone = (phone: string) => {
    const re = /^\d{10}$/ // Assumes a 10-digit phone number
    return re.test(phone)
  }

  const validateZip = (zip: string) => {
    const re = /^\d{5}$/ // Assumes a 5-digit zip code
    return re.test(zip)
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    // Check for errors before submitting
    if (errors.email || errors.phone || errors.zip) {
      setErrorCreatingContact('Please fix the errors before submitting.')
      return
    }

    // ensure filled fields are being returned as a json object for hubspot post req
    try {
      setLoading(true)
      const transformedData = {
        properties: Object.keys(contactData).map(key => ({
          property: key,
          value: contactData[key as keyof typeof contactData],
        })),
      }

      // call api function passing json body for values
      const response = await createHubspotContact(transformedData)
      if (!response) {
        setErrorCreatingContact('Error creating contact.')
        setLoading(false)
        return
      }

      // Call the function to pull the latest contacts
      try {
        await pullHubspotContacts()
      } catch (error) {
        console.error('Error pulling contacts', error)
        setErrorCreatingContact('Write something specific here')
      }

      // Alert on successful contact creation
      window.alert('New contact successfully created!')

      handleClose()
    } catch (error) {
      console.error('Error creating contact', error)
      setErrorCreatingContact('Write something specific here')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Box component='form' noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id='email'
            label='Email Address'
            name='email'
            autoComplete='email'
            value={contactData.email}
            onChange={handleChange}
            error={!!errors.email}
            helperText={errors.email}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id='firstname'
            label='First Name'
            name='firstname'
            autoComplete='fname'
            value={contactData.firstname}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id='lastname'
            label='Last Name'
            name='lastname'
            autoComplete='lname'
            value={contactData.lastname}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id='website'
            label='Website (Optional)'
            name='website'
            autoComplete='website'
            value={contactData.website}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id='company'
            label='Company (Optional)'
            name='company'
            autoComplete='company'
            value={contactData.company}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id='phone'
            label='Phone (Optional)'
            name='phone'
            autoComplete='phone'
            value={contactData.phone}
            onChange={handleChange}
            error={!!errors.phone}
            helperText={errors.phone}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id='address'
            label='Address (Optional)'
            name='address'
            autoComplete='address'
            value={contactData.address}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id='city'
            label='City (Optional)'
            name='city'
            autoComplete='city'
            value={contactData.city}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id='state'
            label='State (Optional)'
            name='state'
            autoComplete='state'
            value={contactData.state}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id='zip'
            label='Zip (Optional)'
            name='zip'
            autoComplete='zip'
            value={contactData.zip}
            onChange={handleChange}
            error={!!errors.zip}
            helperText={errors.zip}
          />
        </Grid>
        <Typography sx={{ color: 'red' }}>{errorCreatingContact && `${errorCreatingContact}`}</Typography>
        <Button type='submit' fullWidth variant='contained' sx={{ mt: 3, mb: 2 }}>
          Create Contact
        </Button>
      </Grid>
      <Backdrop open={loading} style={{ zIndex: 1 }}>
        <CircularProgress color='inherit' />
      </Backdrop>
    </Box>
  )
}

export default CreateUserForm
