import { API_URL } from 'core/config'
import { getHeaders } from 'core/api/getHeaders'

export const getUserInfo = async (): Promise<UserInfoType | null> => {
  try {
    const response = await fetch(`${API_URL}/users/me`, {
      method: 'GET',
      headers: getHeaders(),
    })

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`)
    }
    const res = await response.json()
    return res
  } catch (error) {
    console.error('There was an error!', error)
    return null
  }
}
