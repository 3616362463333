import { getHeaders, functionUrls } from 'core/api'

export const pullHubspotTickets = async () => {
  const functionUrl: string = functionUrls.pullHubspotTickets
  try {
    const res = await fetch(functionUrl, {
      method: 'GET',
      headers: getHeaders(),
    })

    console.log('pullHubspotTickets Response status:', res.status)

    if (res.status === 200) {
      console.log('Cloud Function triggered successfully.')
    } else {
      throw new Error(`Request failed with status ${res.status}`)
    }
  } catch (error) {
    console.error('Error in pulling tickets from HubSpot')
    throw error
  }
}
