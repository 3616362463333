/* eslint-disable */

// TODO: Remove eslint-disable and clean this file up

import React, { useEffect, useState } from 'react'
import { MainLayout } from 'layouts'
import { Typography, Box, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import ViewTruckDebtOverhaulSubmissions from './components/ViewTruckDebtOverhaulSubmissions'
import { PageTitle } from 'components/PageTitle'
import { adminFunctions } from 'core/api/application/admin'

interface ClientInfo {
  id: string
  company_name: string
  name: string
  phone_number: string
  email: string
  has_unreviewed_submissions: boolean
  has_pending_submissions: boolean
  has_completed_submissions: boolean
}

const TruckDebtOverhaulManagement = () => {
  const [clients, setClients] = useState<ClientInfo[]>([])
  const [clientAndStatus, setClientAndStatus] = useState({
    clientId: '',
    status: '',
  })
  const [truckDebtSubmissionsVisible, setTruckDebtSubmissionsVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [errorDialogOpen, setErrorDialogOpen] = useState(false)

  const onCloseTruckDebtSubmissions = () => {
    setTruckDebtSubmissionsVisible(false)
  }

  const onOpenTruckDebtSubmissions = () => {
    setTruckDebtSubmissionsVisible(true)
  }

  const handleCloseErrorDialog = () => {
    setErrorDialogOpen(false)
  }

  const ErrorDialog = () => (
    <Dialog open={errorDialogOpen} onClose={handleCloseErrorDialog}>
      <DialogTitle>Error</DialogTitle>
      <DialogContent>
        <Typography>
          There was an error loading the client data. Please try again or submit a support request.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button href='/support-request'>Submit Support Request</Button>
        <Button onClick={handleCloseErrorDialog}>Close</Button>
      </DialogActions>
    </Dialog>
  )

  const fetchClients = async () => {
    setIsLoading(true)
    const data = await adminFunctions.getTruckDebtOverhaulClients()

    if (data) {
      const clients = await Promise.all(
        data.map(async (client: ClientInfo) => {
          const checkClientSubmissionStatuses = await adminFunctions.getStatusesForClientSubmissions(
            parseInt(client.id),
          )

          const clientInfo = {
            id: client.id,
            name: client.name,
            email: client.email,
            phone_number: client.phone_number,
            company_name: client.company_name,
            has_unreviewed_submissions: checkClientSubmissionStatuses['unreviewed'] ? true : false,
            has_pending_submissions: checkClientSubmissionStatuses['pending'] ? true : false,
            has_completed_submissions: checkClientSubmissionStatuses['completed'] ? true : false,
          }

          return clientInfo
        }),
      )

      setClients(clients)
    } else {
      setErrorDialogOpen(true)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    fetchClients()
  }, [truckDebtSubmissionsVisible])

  const affiliateColumns = [
    { field: 'id', headerName: 'ID', width: 50 },
    { field: 'name', headerName: 'Name', width: 200 },
    { field: 'email', headerName: 'Email', width: 250 },
    { field: 'phone', headerName: 'Phone', width: 150 },
    { field: 'company_name', headerName: 'Company Name', width: 275 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 700,
      renderCell: (params: {
        row: {
          has_unreviewed_submissions: any
          id: any
          has_pending_submissions: any
          has_completed_submissions: any
        }
      }) => (
        <strong>
          {params.row.has_unreviewed_submissions && (
            <Button
              onClick={() => {
                setClientAndStatus({
                  clientId: `${params.row.id}`,
                  status: 'UNREVIEWED',
                })
                onOpenTruckDebtSubmissions()
              }}
              variant='contained'
              color='primary'
              size='small'
            >
              Unreviewed
            </Button>
          )}

          {params.row.has_pending_submissions && (
            <Button
              onClick={() => {
                setClientAndStatus({
                  clientId: `${params.row.id}`,
                  status: 'PENDING',
                })
                onOpenTruckDebtSubmissions()
              }}
              variant='contained'
              color='info'
              size='small'
              sx={{ marginLeft: 1 }}
            >
              Pending
            </Button>
          )}

          {params.row.has_completed_submissions && (
            <Button
              onClick={() => {
                setClientAndStatus({
                  clientId: `${params.row.id}`,
                  status: 'COMPLETED',
                })
                onOpenTruckDebtSubmissions()
              }}
              variant='contained'
              color='success'
              size='small'
              sx={{ marginLeft: 1 }}
            >
              Completed
            </Button>
          )}

          <Button
            onClick={() => {
              setClientAndStatus({
                clientId: `${params.row.id}`,
                status: '',
              })
              onOpenTruckDebtSubmissions()
            }}
            variant='contained'
            color='inherit'
            size='small'
            sx={{ marginLeft: 1 }}
          >
            All Submissions
          </Button>
        </strong>
      ),
    },
  ]

  return (
    <MainLayout>
      <ErrorDialog />
      <ViewTruckDebtOverhaulSubmissions
        clientId={clientAndStatus.clientId}
        status={clientAndStatus.status}
        isOpen={truckDebtSubmissionsVisible}
        onClose={onCloseTruckDebtSubmissions}
      />

      <PageTitle
        title='Truck Debt Overhaul Management'
        subtitle='This page allows you to view all clients and their truck debt overhaul submissions.'
        bulletPoints={[
          "Click the 'Unreviewed' button to view all unreviewed submissions for a client.",
          "Click the 'Pending' button to view all pending submissions for a client.",
          "Click the 'Completed' button to view all completed submissions for a client.",
          "Click the 'All Submissions' button to view all submissions for a client.",
        ]}
      />

      <Box sx={{ height: '75vh', width: '100%' }}>
        <DataGrid
          rows={clients}
          columns={affiliateColumns}
          rowHeight={50}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10]}
          getRowId={row => row.id}
          loading={isLoading}
        />
      </Box>
    </MainLayout>
  )
}

export default TruckDebtOverhaulManagement
