import React from 'react'
import { Dialog, DialogTitle, DialogContent, Button, Typography, Box } from '@mui/material'

interface ViewLeadDetailsDialogProps {
  open: boolean
  onClose: () => void
  lead: Lead
}

const ViewLeadDetailsDialog = ({ open, onClose, lead }: ViewLeadDetailsDialogProps) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth='sm'>
      <DialogTitle>Lead Details</DialogTitle>
      <DialogContent>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <Typography variant='subtitle1'>
            Name:{' '}
            <Typography variant='body2' component='span'>
              {lead?.name}
            </Typography>
          </Typography>
          <Typography variant='subtitle1'>
            Email:{' '}
            <Typography variant='body2' component='span'>
              {lead?.email}
            </Typography>
          </Typography>
          <Typography variant='subtitle1'>
            Phone:{' '}
            <Typography variant='body2' component='span'>
              {lead?.phone}
            </Typography>
          </Typography>
          <Typography variant='subtitle1'>
            Address:{' '}
            <Typography variant='body2' component='span'>
              {lead?.address}
            </Typography>
          </Typography>
          <Typography variant='subtitle1'>
            City:{' '}
            <Typography variant='body2' component='span'>
              {lead?.city}
            </Typography>
          </Typography>
          <Typography variant='subtitle1'>
            State:{' '}
            <Typography variant='body2' component='span'>
              {lead?.state}
            </Typography>
          </Typography>
          <Typography variant='subtitle1'>
            Information:{' '}
            <Typography variant='body2' component='span'>
              {lead?.information}
            </Typography>
          </Typography>
          <Typography variant='subtitle1'>
            Number of Trucks:{' '}
            <Typography variant='body2' component='span'>
              {lead?.number_of_trucks}
            </Typography>
          </Typography>
          <Typography variant='subtitle1'>
            Type:{' '}
            <Typography variant='body2' component='span'>
              {lead?.type}
            </Typography>
          </Typography>
          <Typography variant='subtitle1'>
            Value:{' '}
            <Typography variant='body2' component='span'>
              {lead?.value || 'N/A'}
            </Typography>
          </Typography>
          <Typography variant='subtitle1'>
            Status:{' '}
            <Typography variant='body2' component='span'>
              {lead?.status}
            </Typography>
          </Typography>
          <Typography variant='subtitle1'>
            Affiliate Company Name:{' '}
            <Typography variant='body2' component='span'>
              {lead?.affiliateCompanyName}
            </Typography>
          </Typography>
          <Typography variant='subtitle1'>
            Affiliate Name:{' '}
            <Typography variant='body2' component='span'>
              {lead?.affiliateName}
            </Typography>
          </Typography>
          <Typography variant='subtitle1'>
            Affiliate Email:{' '}
            <Typography variant='body2' component='span'>
              {lead?.affiliateEmail}
            </Typography>
          </Typography>
        </Box>
      </DialogContent>
      <Box textAlign='center' m={2}>
        <Button onClick={onClose}>Close</Button>
      </Box>
    </Dialog>
  )
}

export default ViewLeadDetailsDialog
