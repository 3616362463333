import { getHeaders, functionUrls } from 'core/api'

export const pullHubspotContacts = async () => {
  const functionUrl: string = functionUrls.pullHubspotContact
  try {
    const res = await fetch(functionUrl, {
      method: 'POST',
      headers: getHeaders(),
    })

    console.log('pullHubspotContacts Response status:', res.status)

    if (res.status === 200) {
      console.log('Cloud Function triggered successfully.')
    } else {
      throw new Error(`Request failed with status ${res.status}`)
    }
  } catch (error) {
    console.error('Error in pulling contacts from HubSpot')
    throw error
  }
}
