import React, { useState } from 'react'
import { Dialog, DialogContent, DialogActions, DialogTitle, Button } from '@mui/material'
import CreateUserForm from './CreateUserForm'

interface CreateUserProps {
  open: boolean
  handleClose: () => void
}

// creates func component to take user props
// descrutures prop passed
const CreateUser: React.FC<CreateUserProps> = ({ open, handleClose }) => {
  // error catch
  const [errorCreatingContact, setErrorCreatingContact] = useState<string | null>(null)

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Create New HubSpot Contact</DialogTitle>
      <DialogContent>
        <CreateUserForm
          handleClose={handleClose}
          setErrorCreatingContact={setErrorCreatingContact}
          errorCreatingContact={errorCreatingContact}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  )
}

export default CreateUser
