import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import { getPhotoUploadLink } from 'core/api/photoUploader/getPhotoUploadLink'
import { sendPhotoUploadLink } from 'core/utils/sendPhotoUploadLink'
import React from 'react'

interface PhotoUploadDialogProps {
  open: boolean
  onClose: () => void
  vehicle: Vehicle
}

const PhotoUploadDialog: React.FC<PhotoUploadDialogProps> = ({ open, onClose, vehicle: row }) => {
  const [phoneNumber, setPhoneNumber] = React.useState('')
  const [email, setEmail] = React.useState('')

  const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(event.target.value)
  }

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  }

  const handleSendPhotoUpload = async () => {
    if (email && !email.includes('@')) {
      alert('Please enter a valid email address.')
      return
    }
    if (phoneNumber && phoneNumber.length < 10) {
      alert('Please enter a valid phone number.')
      return
    }

    try {
      onClose()

      if (!row) {
        console.error('No selected row to send photo upload link.')
        return
      }
      const link = await getPhotoUploadLink({ email, vehicleData: row })
      await sendPhotoUploadLink({ link, email, phoneNumber })
      setPhoneNumber('')
      setEmail('')
    } catch (error) {
      console.error('Failed to send photo upload link:', error)
    }
  }

  const isSendDisabled = !phoneNumber || !email

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby='form-dialog-title'>
      <DialogTitle id='form-dialog-title'>Send photo upload link to:</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin='dense'
          id='phone'
          label='Phone Number'
          type='text'
          fullWidth
          required
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
        />
        <TextField
          margin='dense'
          id='email'
          label='Email Address'
          type='email'
          fullWidth
          required
          value={email}
          onChange={handleEmailChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          Cancel
        </Button>
        <Button onClick={handleSendPhotoUpload} color='primary' disabled={isSendDisabled}>
          Send
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PhotoUploadDialog
