import { doc, getDoc } from '@firebase/firestore'
import { db } from 'core/config/firebase'




export const getUserProfile = async (userId: string): Promise<UserInfoType | undefined> => {
  const userRef = doc(db, `users/${userId}`)

  const docSnap = await getDoc(userRef)


  // console.log('docSnap', userId)

  // if (docSnap.exists()) {
    const docData = docSnap.data()

    if (!docData) {
      console.error("Could not find user with id: ", userId)
      return undefined;
    }

    return  {
      id: docSnap.id,
      email: docData.email,
      first_name: docData.first_name,
      last_name: docData.last_name,
      is_active: docData.is_active,
      email_verified: docData.email_verified,
      user_type: docData.user_type,
      entity_structure: docData.entity_structure,
      company_name: docData.company_name,
      bank_id: docData.bank_id,
      affiliate_id: docData.affiliate_id,
      status: docData.status,
      dealership_name: docData.dealership_name,
      notes: docData.notes,
      w9_document_uuid: docData.w9_document_uuid,
    }
  // } else {
  //   console.error("Could not find user with id: ", userId)
  //   return undefined;
  // }
}
