import { CarCrash, Delete, PhotoAlbumOutlined, Visibility, VisibilityOff } from '@mui/icons-material'
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  CircularProgress,
  Divider,
  IconButton,
  Switch,
  Typography,
} from '@mui/material'
import { useAuth } from 'contexts/AuthContext'
import { getPhotosForInventoryObject } from 'core/api/inventory/getPhotosForInventoryObject'
import React from 'react'
import { useQuery } from 'react-query'

interface VehicleCardProps {
  vehicle: Vehicle
  onClick: () => void
  onDeleteClick: () => void
  onManagePhotosClick: () => void
  onShowHideOnWebsiteClick: () => void
  onPhotoUploadClick: () => void
}

const VehicleCard: React.FC<VehicleCardProps> = ({
  vehicle,
  onClick,
  onDeleteClick,
  onManagePhotosClick,
  onShowHideOnWebsiteClick,
  onPhotoUploadClick,
}) => {
  const { userInfo } = useAuth()

  const { data: photos, isLoading } = useQuery(['photos', vehicle.id], async () =>
    getPhotosForInventoryObject(vehicle.id),
  )

  return (
    <>
      <Card sx={{ minWidth: 275 }} onClick={onClick}>
        {isLoading ? (
          <Box display='flex' justifyContent='center' alignItems='center' height={200}>
            <CircularProgress sx={{ mt: 2 }} />
          </Box>
        ) : photos && photos.length > 0 ? (
          <CardMedia
            component='img'
            srcSet={`${photos[0].url}?w=82&h=82&fit=crop&auto=format&dpr=2 2x`}
            src={`${photos[0].url}?w=82&h=82&fit=crop&auto=format`}
            alt={photos[0].name}
            loading='lazy'
            height={200}
          />
        ) : (
          <Box display='flex' justifyContent='center' alignItems='center' height={200}>
            {/* Replace with your desired placeholder component */}
            <Typography>No Photo Available</Typography>
          </Box>
        )}
        <CardHeader
          action={
            <IconButton
              aria-label='Delete Vehicle'
              onClick={event => {
                event.stopPropagation()
                console.log('click delete')
                onDeleteClick()
              }}
            >
              <Delete />
            </IconButton>
          }
          title={`${vehicle.model} ${vehicle.make} ${vehicle.size}`}
          subheader={`Stock Number: ${vehicle.stock_number}`}
        />
        <CardContent>
          <Divider />
          <Box display='flex' flexDirection='column' mt={1} gap={1}>
            <Typography sx={{ fontWeight: 'bold' }}>VIN: {vehicle.vin}</Typography>
            <Typography>Year: {vehicle.year}</Typography>
            <Typography>Miles: {vehicle.miles}</Typography>
          </Box>
        </CardContent>
        <CardActions>
          <IconButton
            aria-label='Manage Photos'
            onClick={event => {
              event.stopPropagation()
              onManagePhotosClick()
            }}
            disabled={!userInfo?.email_verified}
          >
            <PhotoAlbumOutlined />
          </IconButton>
          <Switch
            checked={vehicle.show_on_website}
            icon={<VisibilityOff />}
            checkedIcon={<Visibility />}
            onClick={event => {
              event.stopPropagation()
            }}
            onChange={onShowHideOnWebsiteClick}
            color={vehicle.show_on_website ? 'success' : 'error'}
          />
          <IconButton
            aria-label='Upload Photos'
            onClick={event => {
              event.stopPropagation()
              onPhotoUploadClick()
            }}
            disabled={!userInfo?.email_verified}
          >
            <CarCrash />
          </IconButton>
        </CardActions>
      </Card>
    </>
  )
}

export default VehicleCard
