import { API_URL } from 'core/config'
import { getHeaders } from 'core/api/getHeaders'

export const submitSupportRequest = async (issueType: string, description: string): Promise<boolean> => {
  try {
    const response = await fetch(`${API_URL}/submit-support-request`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify({ subject: issueType, message: description }),
    })

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`)
    }

    const data = await response.json()

    if (data) {
      return true
    } else {
      console.error('Error submitting support request:', data)
      return false
    }
  } catch (error) {
    console.error('Error in submit support request:', error)
    throw error
  }
}
