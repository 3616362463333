import React, { useEffect } from 'react'
import { useTheme, Box, Typography, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useAuth } from 'contexts/AuthContext'
import { IsLoading } from 'components'
import { AuthLayout } from 'layouts'

export default function NotFound() {
  const navigate = useNavigate()
  const [redirectTo, setRedirectTo] = React.useState('/')
  const { isAuthenticated, isLoading, userInfo, logout } = useAuth()
  const theme = useTheme()

  if (isLoading) {
    return <IsLoading />
  }

  useEffect(() => {
    if (isAuthenticated) {
      if (userInfo?.user_type === 'babyAdmin') {
        navigate('/admin')
      } else {
        navigate(`/${userInfo?.user_type}`)
      }
    }

    if (!isAuthenticated) {
      setRedirectTo('/login')
    } 
  })

  return (
    <AuthLayout>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          margin: theme.spacing(2),
          textAlign: 'center',
        }}
      >
        <Typography component='h1' variant='h4' gutterBottom>
          404 - Page Not Found
        </Typography>
        <Typography variant='subtitle1' gutterBottom>
          Sorry, we couldn&apos;'t find the page you're looking for.
        </Typography>
        <Button
          variant='contained'
          color='primary'
          onClick={() => navigate(redirectTo)}
          sx={{ mt: theme.spacing(2) }}
        >
          Go to Home
        </Button>
        <Button variant='contained' color='primary' onClick={() => logout()} sx={{ mt: theme.spacing(2) }}>
          Logout
        </Button>
      </Box>
    </AuthLayout>
  )
}
