/* eslint-disable */

import React, { useState, useEffect } from 'react'
import Main from 'layouts/MainLayout'
import {
  TextField,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
  Tabs,
  Tab,
  CircularProgress,
  Divider,
} from '@mui/material'
import AddNotesDialog from './components/AddNotesDialog'
import AssignAgentDialog from './components/AssignAgentDialog'
import ViewApprovalTerms from './components/ViewApprovalTerms'
import AssociateVehiclesDialog from '../../../../components/AssociateVehiclesDialog'
import ViewDocumentsDialog from './components/ViewDocumentsDialog'
import { useTheme, useMediaQuery } from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select'
import { PageTitle } from 'components/PageTitle'
import { adminFunctions } from 'core/api/application/admin'
import { useAdmin } from 'contexts/AdminContext'
import { formatDateToLocalTime } from '../../../../core/utils/formatDateToLocalTime'

const ApplicationManagement = () => {
  //   const [applications, setApplications] = useState<Application[]>([])
  const [filteredApps, setFilteredApps] = useState<Application[]>([])
  const [tabIndex, setTabIndex] = useState(0)
  const [loadingDialogOpen, setLoadingDialogOpen] = useState(false)
  const [errorDialogOpen, setErrorDialogOpen] = useState(false)
  const [documentUrl, setDocumentUrl] = useState<string | null>(null)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [isAssignAgentDialogOpen, setAssignAgentDialog] = useState(false)
  // const [agents, setAgents] = useState<BankAgent[]>([]);
  const [selectedBankId, setSelectedBankId] = useState<string>('')
  const [selectedBankName, setSelectedBankName] = useState<string>('')
  const [selectedAgentId, setSelectedAgentId] = useState<string>('')
  const [selectedApplicationId, setSelectedApplicationId] = useState<string>('')
  const [isAddNotesDialogOpen, setAddNotesDialogOpen] = useState(false)
  const [selectedApplication, setSelectedApplication] = useState<Application | null>(null)
  const [correctioNotes, setCorrectionNotes] = useState('')
  const [searchQuery, setSearchQuery] = useState('')

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  //   const fetchApplications = () => {
  //     adminFunctions.getApplications().then(res => {
  //       setApplications(res)
  //     })
  //   }

  const { applications, refetchApplications, banks } = useAdmin()

  // useEffect(() => {
  //     fetchApplications();
  // }, [tabIndex, agents, isAssignAgentDialogOpen]);

  useEffect(() => {
    refetchApplications()
  }, [tabIndex, isAssignAgentDialogOpen])

  useEffect(() => {
    if (!applications) return

    const formattedApplications = applications.map(app => ({
      ...app,
      time_created: formatDateToLocalTime(app.time_created),
    }))

    // Sort the applications by date, newest first
    const sortedApplications = formattedApplications.sort((a, b) => {
      return new Date(b.time_created).getTime() - new Date(a.time_created).getTime()
    })

    const filtered = sortedApplications.filter(app => {
      const query = searchQuery.toLowerCase()
      const matchesSearchQuery =
        app.status.toLowerCase().includes(query) ||
        app.bank_name.toLowerCase().includes(query) ||
        app.user_email.toLowerCase().includes(query) ||
        app.time_created.toLowerCase().includes(query) ||
        (app.notes && app.notes.toLowerCase().includes(query)) ||
        (app.correction_notes && app.correction_notes.toLowerCase().includes(query)) ||
        (app.bank_decision_notes && app.bank_decision_notes.toLowerCase().includes(query)) ||
        app.application_id.toString().includes(query) ||
        (app.bank_agent && app.bank_agent.toLowerCase().includes(query))

      const matchesStatus = () => {
        switch (tabIndex) {
          case 0:
            return app.status === 'Ready'
          case 1:
            return app.status === 'Corrections Needed'
          case 2:
            return app.status === 'Pending'
          case 3:
            return app.status === 'Viewed'
          case 4:
            return app.status === 'Approved' && app.completed !== true
          case 5:
            return app.status === 'Denied' && app.completed !== true
          case 6:
            return app.completed === true
          default:
            return false
        }
      }

      return matchesSearchQuery && matchesStatus()
    })

    setFilteredApps(filtered)
  }, [tabIndex, applications, searchQuery])

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue)
  }

  const handleSelectTabChange = (event: SelectChangeEvent<number>) => {
    const value = event.target.value as number // Cast the value to a number
    setTabIndex(value)
  }

  const handleCloseDialog = () => {
    setIsDialogOpen(false)
    setDocumentUrl(null)
  }

  const handleCloseErrorDialog = () => {
    setErrorDialogOpen(false)
  }

  const handleAssignAgent = () => {
    assignAgent(selectedApplicationId, selectedAgentId)
    setAssignAgentDialog(false)
  }

  const assignAgent = (application_id: string, agent_id: string) => {
    adminFunctions
      .assignApplicationToAgent(application_id, agent_id)
      .then(() => {
        refetchApplications()
      })
      .catch(error => {
        console.error('Error during agent assignment:', error)
      })
  }

  const openAssignAgentDialog = (application: Application) => {
    setSelectedApplicationId(application.application_id)
    setSelectedBankId(application.bank_id)
    setSelectedBankName(application.bank_name)
    setAssignAgentDialog(true)
  }

  const markApplicationCompleted = async (application_id: string) => {
    await adminFunctions.markApplicationAsComplete(application_id)
    refetchApplications()
  }

  const handleOpenAddNotesDialog = (application: Application) => {
    setSelectedApplication(application)
    setAddNotesDialogOpen(true)
  }

  const handleCloseAddNotesDialog = () => {
    setAddNotesDialogOpen(false)
  }

  const handleViewDocument = async (application: Application) => {
    setLoadingDialogOpen(true)
    setSelectedApplicationId(application.application_id)

    try {
      const url = await adminFunctions.getViewableDocument(application.document_uuid)

      if (url === '') {
        setErrorDialogOpen(true)
        setLoadingDialogOpen(false)
        return
      }

      setDocumentUrl(url)
      setLoadingDialogOpen(false)
      setIsDialogOpen(true)
    } catch (error) {
      console.error('Error fetching document:', error)
    }
  }

  const handleSendDocumentBackForCorrections = async (application_id: string, correction_notes: string) => {
    try {
      adminFunctions.sendDocumentBackForCorrections(application_id, correction_notes).then(() => {
        refetchApplications()
        setDocumentUrl(null)
      })
    } catch (error) {
      setErrorDialogOpen(true)
      setLoadingDialogOpen(false)
    }
  }

  const LoadingDialog = () => (
    <Dialog open={loadingDialogOpen} onClose={handleCloseLoadingDialog}>
      <DialogTitle>Loading Document..</DialogTitle>
      <DialogContent>
        <CircularProgress />
      </DialogContent>
    </Dialog>
  )

  const handleCloseLoadingDialog = () => {
    setErrorDialogOpen(false)
  }

  const [viewApprovalNotesDialogOpen, setViewApprovalNotesDialogOpen] = useState(false)
  // const [selectedApplication, setSelectedApplication] = useState<Application | null>(null);

  const handleOpenViewApprovalNotesDialog = (application: Application) => {
    setSelectedApplication(application)
    setViewApprovalNotesDialogOpen(true)
  }

  const handleCloseViewApprovalNotesDialog = () => {
    setViewApprovalNotesDialogOpen(false)
  }

  const ErrorDialog = () => (
    <Dialog open={errorDialogOpen} onClose={handleCloseErrorDialog}>
      <DialogTitle>Error</DialogTitle>
      <DialogContent>
        <Typography>
          There was an error loading the document. Please try again or submit a support request.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button href='/support-request'>Submit Support Request</Button>
        <Button onClick={handleCloseErrorDialog}>Close</Button>
      </DialogActions>
    </Dialog>
  )

  const [isAssociateVehiclesDialogOpen, setAssociateVehiclesDialogOpen] = useState(false)

  const handleOpenAssociateVehiclesDialog = (application: Application) => {
    setSelectedApplication(application)
    setAssociateVehiclesDialogOpen(true)
  }

  const handleCloseAssociateVehiclesDialog = () => {
    setAssociateVehiclesDialogOpen(false)
  }

  const [viewDocumentsDialog, setViewDocumentsDialog] = useState(false)

  const handleOpenViewDocumentsDialog = (application: Application) => {
    setSelectedApplication(application)

    setViewDocumentsDialog(true)
  }

  const handleCloseViewDocumentsDialog = () => {
    setViewDocumentsDialog(false)
  }

  return (
    <Main>
      <ViewApprovalTerms
        application={selectedApplication}
        isOpen={viewApprovalNotesDialogOpen}
        onClose={handleCloseViewApprovalNotesDialog}
      />

      <AssignAgentDialog
        isOpen={isAssignAgentDialogOpen}
        onAssign={handleAssignAgent}
        onClose={() => setAssignAgentDialog(false)}
        setSelectedAgentId={setSelectedAgentId}
        selectedBankId={selectedBankId}
        selectedBankName={selectedBankName}
        selectedAgentId={selectedAgentId}
      />

      <AddNotesDialog
        application={selectedApplication}
        isOpen={isAddNotesDialogOpen}
        onClose={handleCloseAddNotesDialog}
      />

      <AssociateVehiclesDialog
        application={selectedApplication}
        isOpen={isAssociateVehiclesDialogOpen}
        onClose={handleCloseAssociateVehiclesDialog}
      />

      <ViewDocumentsDialog
        application={selectedApplication}
        isOpen={viewDocumentsDialog}
        onClose={handleCloseViewDocumentsDialog}
      />

      <ErrorDialog />
      <LoadingDialog />

      <Box display='flex' flexDirection='column'>
        <PageTitle
          title='Application Management'
          subtitle='Manage applications and their statuses.'
          bulletPoints={[
            'Use the tabs to filter applications by status.',
            "Click 'View' to view the application document.",
            "Click 'Download Documents' to download all documents associated with the application.",
            "Click 'Assign to Agent' to assign the application to a bank agent.",
            "Click 'Add Bank Notes' to add notes to the application.",
            "Click 'View Approval Terms' to view the approval terms.",
            "Click 'Associate Vehicles' to associate vehicles with the application.",
            "Click 'Mark Completed' to mark the application as completed.",
          ]}
        />
        <TextField
          // fullWidth
          label='Keyword Search (name, bank, email, notes, etc...)'
          variant='outlined'
          value={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
          sx={{ mb: 2 }}
        />
      </Box>

      {isMobile ? (
        <FormControl fullWidth>
          <InputLabel id='tab-select-label'>Status</InputLabel>
          <Select
            labelId='tab-select-label'
            id='tab-select'
            value={tabIndex}
            onChange={handleSelectTabChange}
            label='Status'
          >
            <MenuItem value={0}>Ready</MenuItem>
            <MenuItem value={1}>Client is Correcting</MenuItem>
            <MenuItem value={2}>Pending Bank Review</MenuItem>
            <MenuItem value={3}>Viewed by Bank</MenuItem>
            <MenuItem value={4}>Approved</MenuItem>
            <MenuItem value={5}>Denied</MenuItem>
            <MenuItem value={6}>Completed</MenuItem>
          </Select>
        </FormControl>
      ) : (
        <Tabs value={tabIndex} onChange={handleTabChange} centered>
          <Tab label='Ready' />
          <Tab label='Client is Correcting' />
          <Tab label='Pending Bank Review' />
          <Tab label='Viewed by Bank' />
          <Tab label='Approved' />
          <Tab label='Denied' />
          <Tab label='Completed' />
        </Tabs>
      )}

      <Divider sx={{ marginBottom: 2 }} />

      <Grid container spacing={2}>
        {filteredApps.map((application: Application) => (
          <Grid item lg={4} md={4} sm={6} xs={12} key={application.application_id}>
            <Card
              variant='outlined'
              elevation={3} // Add elevation for shadow
              sx={{
                backgroundColor: '#fff', // White background for contrast
                borderRadius: '12px', // Rounded corners
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', // Soft shadow for better visibility
                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                '&:hover': {
                  transform: 'scale(1.02)', // Slightly enlarge the card on hover
                  boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)', // Increase shadow on hover
                },
              }}
              onClick={() => handleViewDocument(application)}
            >
              {' '}
              <CardContent>
                <Box display='flex' justifyContent='space-between'>
                  <Box>
                    <Typography variant='h6'>ID</Typography>
                    <Typography variant='body2'>{application.application_id}</Typography>

                    <Typography variant='h6'>User Email</Typography>
                    <Typography variant='body2'>{application.user_email}</Typography>

                    <Typography variant='h6'>Date Submitted</Typography>
                    <Typography variant='body2'>{application.time_created}</Typography>
                  </Box>

                  <Box>
                    <Typography variant='h6'>Bank Name</Typography>
                    <Typography variant='body2'>{application.bank_name}</Typography>
                    <Typography variant='h6'>Bank Agent</Typography>
                    <Typography variant='body2'>{application.bank_agent}</Typography>

                    <Typography variant='h6'>Status</Typography>
                    <Typography variant='body2'>{application.status}</Typography>
                  </Box>
                </Box>

                <Box mt={4} display='flex' gap={1} justifyContent='center' flexWrap='nowrap'>
                  {/* <Button
                    onClick={() => handleViewDocument(application)}
                    variant='contained'
                    color='primary'
                    size='small'
                  >
                    <Typography variant='body2'>Review Application</Typography>
                  </Button> */}

                  {tabIndex === 0 && (
                    <Button
                      onClick={e => {
                        e.stopPropagation()
                        openAssignAgentDialog(application)
                      }}
                      variant='contained'
                      color='primary'
                      size='small'
                      sx={{
                        borderRadius: '12px', // Rounded corners
                        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', // Soft shadow for better visibility
                        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                        '&:hover': {
                          transform: 'scale(1.05)', // Slightly enlarge the card on hover
                          color: '#fff', // White text on hover
                        },
                      }}
                    >
                      Assign Agent
                    </Button>
                  )}

                  {(tabIndex === 4 || tabIndex === 5) && (
                    <Button
                      onClick={e => {
                        e.stopPropagation()
                        markApplicationCompleted(application.application_id)
                      }}
                      variant='contained'
                      color='primary'
                      size='small'
                      sx={{
                        borderRadius: '12px', // Rounded corners
                        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', // Soft shadow for better visibility
                        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                        '&:hover': {
                          transform: 'scale(1.05)', // Slightly enlarge the card on hover
                          color: '#fff', // White text on hover
                        },
                      }}
                    >
                      Mark Completed
                    </Button>
                  )}

                  {tabIndex !== 4 && tabIndex !== 5 && tabIndex !== 6 && (
                    <Button
                      onClick={e => {
                        e.stopPropagation()
                        handleOpenAddNotesDialog(application)
                      }}
                      variant='contained'
                      color='primary'
                      size='small'
                      sx={{
                        borderRadius: '12px', // Rounded corners
                        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', // Soft shadow for better visibility
                        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                        '&:hover': {
                          transform: 'scale(1.05)', // Slightly enlarge the card on hover
                          color: '#fff', // White text on hover
                        },
                      }}
                    >
                    Bank Notes
                    </Button>
                  )}
                  <Button
                    onClick={e => {
                      e.stopPropagation()
                      handleOpenViewDocumentsDialog(application)
                    }}
                    variant='contained'
                    color='primary'
                    size='small'
                    sx={{
                      borderRadius: '12px', // Rounded corners
                      boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', // Soft shadow for better visibility
                      transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                      '&:hover': {
                        transform: 'scale(1.05)', // Slightly enlarge the card on hover
                        color: '#fff', // White text on hover
                      },
                    }}
                  >
                    <Typography variant='body2'>Additional Documents</Typography>
                  </Button>

                  {[4, 5, 6].includes(tabIndex) && (
                    <Button
                    onClick={e => {
                      e.stopPropagation()
                      handleOpenViewApprovalNotesDialog(application)
                    }}
                      variant='contained'
                      color='primary'
                      size='small'
                      sx={{
                        borderRadius: '12px', // Rounded corners
                        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', // Soft shadow for better visibility
                        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                        '&:hover': {
                          transform: 'scale(1.05)', // Slightly enlarge the card on hover
                          color: '#fff', // White text on hover
                        },
                      }}
                    >
                      View Approval Terms
                    </Button>
                  )}

                  {tabIndex === 6 && (
                    <Button
                    onClick={e => {
                      e.stopPropagation()
                      handleOpenAssociateVehiclesDialog(application)
                    }}
                      variant='contained'
                      color='primary'
                      size='small'
                      sx={{
                        borderRadius: '12px', // Rounded corners
                        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', // Soft shadow for better visibility
                        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                        '&:hover': {
                          transform: 'scale(1.05)', // Slightly enlarge the card on hover
                          color: '#fff', // White text on hover
                        },
                      }}
                    >
                      Associate Vehicles
                    </Button>
                  )}
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {documentUrl && (
        <Dialog
          open={isDialogOpen}
          onClose={handleCloseDialog}
          fullWidth
          maxWidth={isMobile ? 'sm' : 'lg'} // Adjust the maxWidth for mobile
          PaperProps={{
            style: {
              height: isMobile ? 'auto' : '100%', // Use auto height for mobile for better flexibility
              maxHeight: isMobile ? '90vh' : 'calc(100vh - 64px)', // Adjust the maxHeight for mobile
              overflowY: 'auto', // Use auto instead of hidden to allow scrolling if needed
            },
          }}
        >
          <DialogContent>
            <iframe
              src={documentUrl}
              style={{
                height: isMobile ? '50vh' : 'calc(100vh - 64px)', // Adjust iframe height for mobile
                width: '100%',
                border: 'none',
              }}
              allowFullScreen
            />
            <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
              <TextField
                fullWidth
                label='Notes'
                multiline
                rows={4}
                margin='dense'
                value={correctioNotes}
                onChange={e => setCorrectionNotes(e.target.value)}
                variant='outlined'
              />
              <Button
                onClick={() => handleSendDocumentBackForCorrections(selectedApplicationId, correctioNotes)}
                variant='contained'
                color='primary'
                sx={{ mt: 2 }} // Add spacing above the button for clarity
              >
                Send Back for Corrections
              </Button>
            </FormControl>
          </DialogContent>
        </Dialog>
      )}
    </Main>
  )
}

export default ApplicationManagement
