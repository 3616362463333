import axios from 'axios';
import { useQuery } from 'react-query';

export interface VINData {
  Variable: string;
  Value: string | null;
}

export const fetchVinData = async (vin: string) => {
  try {
    const response = await axios.get(`https://vpic.nhtsa.dot.gov/api/vehicles/decodevin/${vin}?format=json`);
    return response.data.Results as VINData[];
  } catch (error) {
    throw new Error('Error fetching VIN data. Please check the VIN and try again.');
  }
};

const useVinDecoder = (vin: string) => {
  return useQuery<VINData[], Error>(['vinData', vin], () => fetchVinData(vin), {
    enabled: vin.length === 17,
    refetchOnWindowFocus: false,
  },
);
};

export default useVinDecoder;
