import { getHeaders, functionUrls } from 'core/api'

export const deleteHubspotTicket = async (ticketId: string) => {
  const functionUrl: string = functionUrls.deleteHubspotTicket

  try {
    const res = await fetch(functionUrl, {
      method: 'DELETE',
      headers: getHeaders(),
      body: JSON.stringify({ ticketId }),
    })

    if (res.status === 204) {
      // 204 No Content response
      return
    } else {
      const errorText = await res.text()
      throw new Error(`Request failed with status ${res.status}: ${errorText}`)
    }
  } catch (error) {
    console.error('Error in deleteHubspotTicket:', error)
    throw error
  }
}
