import React, { useEffect, useState } from 'react'
import {
  AppBar,
  Box,
  Toolbar,
  Link,
  Typography,
  MenuItem,
  Tooltip,
  IconButton,
  Menu,
  Avatar,
  useTheme,
  useMediaQuery,
  Divider,
} from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { logoLight } from 'assets'
import { useNavigate } from 'react-router-dom'
import { superuserPages, pages, bankPages, clientPages, dealerPages, NavItem } from 'layouts/navigation'
import { useAuth } from 'contexts/AuthContext'
import { useLocation } from 'react-router-dom'
import { Link as RouterLink } from 'react-router-dom'
import MobileMenu from './MobileMenu'

function Navbar() {
  const { userInfo, logout, isSuperuser } = useAuth()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [userType, setUserType] = useState('')
  const location = useLocation()

  useEffect(() => {
    if (userInfo) {
      setEmail(userInfo.email)
      setUserType(userInfo.user_type)
    }
  }, [userInfo])

  const handleLogout = () => {
    logout()
    navigate('/login')
  }

  let navItems: NavItem[] = pages

  if (userType === 'bankAgent') {
    navItems = bankPages
  } else if (userType === 'client') {
    navItems = clientPages
    if (userInfo?.affiliate_id === null) {
      navItems = [...clientPages, { title: 'Become An Affiliate', path: '/client/become-an-affiliate' }]
    } else if (userInfo?.affiliate_id !== null) {
      navItems = [...clientPages, { title: 'Affiliate Dashboard', path: '/client/affiliate-dashboard' }]
    }
  } else if (userType === 'dealer') {
    navItems = dealerPages
  } else if (isSuperuser()) {
    navItems = superuserPages
  } else {
    navItems = pages
  }

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null)
  const [anchorElDropdown, setAnchorElDropdown] = useState<null | HTMLElement>(null)
  const [dropdownItems, setDropdownItems] = useState<NavItem[]>([])

  const settings = [
    { title: 'Profile', path: '/profile' },
    { title: 'Support', path: '/support-request' },
  ]

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElUser(null)
  }

  const handleCloseUserMenu = (navigatePath: string) => {
    return () => {
      setAnchorElUser(null)
      navigate(navigatePath)
    }
  }

  const handleOpenDropdown = (event: React.MouseEvent<HTMLElement>, items: NavItem[]) => {
    setAnchorElDropdown(event.currentTarget)
    setDropdownItems(items)
  }

  const handleCloseDropdown = () => {
    setAnchorElDropdown(null)
    setDropdownItems([])
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position='static'>
        <Toolbar>
          <Box mr='auto'>
            <Box component='img' src={logoLight} sx={{ width: '10vw', maxWidth: 70, maxHeight: 70 }}></Box>
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'flex' } }} alignItems={'center'}>
            {navItems
              .filter((page): page is NavItem => page !== undefined)
              .map((page: NavItem, i) => (
                <Box marginLeft={4} key={i}>
                  {'submenu' in page ? (
                    <Box
                      display={'flex'}
                      alignItems={'center'}
                      aria-controls={page.title}
                      aria-haspopup='true'
                      // @ts-expect-error page submenu is not known
                      onClick={event => handleOpenDropdown(event, page.submenu)}
                      sx={{
                        cursor: 'pointer',
                        backgroundColor:
                          location.pathname === page.path
                            ? theme.palette.secondary.main
                            : theme.palette.primary.main,
                        borderRadius: theme.shape.borderRadius,
                        padding: theme.spacing(2),
                      }}
                    >
                      {page.icon && <page.icon sx={{ mr: 1 }} />}
                      <Typography
                        color='white'
                        sx={{
                          borderRadius: theme.shape.borderRadius,
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {page.title}
                        <ArrowDropDownIcon sx={{ ml: 1 }} />
                      </Typography>
                    </Box>
                  ) : (
                    <Box
                      display={'flex'}
                      alignItems={'center'}
                      aria-describedby={page.path}
                      sx={{
                        cursor: 'pointer',
                        backgroundColor:
                          location.pathname === page.path
                            ? theme.palette.secondary.main
                            : theme.palette.primary.main,
                        borderRadius: 2,
                        padding: theme.spacing(0.5),
                      }}
                    >
                      {page.icon && <page.icon sx={{ mr: 1 }} />}
                      <Link component={RouterLink} to={page.path} style={{ textDecoration: 'none' }}>
                        <Typography
                          color='white'
                          sx={{
                            padding: theme.spacing(1),
                            borderRadius: theme.shape.borderRadius,
                          }}
                        >
                          {page.title}
                        </Typography>
                      </Link>
                    </Box>
                  )}
                </Box>
              ))}

            <Typography variant={'h6'} color={'white'} sx={{ marginLeft: 20, marginRight: 3 }}>
              {email}
            </Typography>

            <Box marginRight={2}></Box>
          </Box>

          {isMobile && <MobileMenu handleLogout={handleLogout} navigate={navigate} navItems={navItems} />}

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title='Open settings'>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar>
                  {userInfo?.first_name && (
                    <>
                      {userInfo?.first_name.charAt(0)}
                      {userInfo?.last_name?.charAt(0)}
                    </>
                  )}
                </Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id='menu-appbar'
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseNavMenu}
            >
              {settings.map(setting => (
                <MenuItem key={setting.title} onClick={handleCloseUserMenu(setting.path)}>
                  <Typography textAlign='center'>{setting.title}</Typography>
                </MenuItem>
              ))}
              <Divider />
              <MenuItem key='Sign Out' onClick={handleLogout}>
                <Typography textAlign='center'>Sign Out</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>

      <Menu
        id='dropdown-menu'
        anchorEl={anchorElDropdown}
        keepMounted
        open={Boolean(anchorElDropdown)}
        onClose={handleCloseDropdown}
      >
        {dropdownItems.map((item, index) => (
          // @ts-expect-error This is a MenuItem issue with MUI typescript
          <MenuItem
            key={index}
            component={RouterLink}
            to={item.path}
            onClick={handleCloseDropdown}
            sx={{
              backgroundColor: location.pathname === item.path ? theme.palette.primary.dark : 'transparent',
              color: location.pathname === item.path ? 'white' : theme.palette.primary.dark,
              '&:hover': {
                backgroundColor: theme.palette.primary.light,
                color: theme.palette.primary.dark,
              },
            }}
          >
            {item.icon && <item.icon sx={{ mr: 1 }} />}
            {item.title}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}

export default Navbar
