import React, { useState } from 'react'
import { Typography, Box, Collapse, List, ListItem, useTheme } from '@mui/material'
import { FiberManualRecordSharp } from '@mui/icons-material'

interface PageTitleProps {
  title: string
  subtitle?: string
  bulletPoints?: string[]
}

export const PageTitle = (props: PageTitleProps) => {
  const theme = useTheme()
  const [showBullets, setShowBullets] = useState(false)

  return (
    <Box sx={{ marginBottom: 1 }}>
      <Typography variant='h4' component='h1' gutterBottom>
        {props.title}
      </Typography>
      {props.subtitle && (
        <Typography variant='subtitle1' gutterBottom>
          {props.subtitle}
          {props.bulletPoints && (
            <>
              {' '}
              <Typography
                variant='subtitle1'
                component='span'
                sx={{
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  fontSize: { sm: 12, xs: 10 },
                  // fontStyle: 'italic'
                }}
                onClick={() => setShowBullets(!showBullets)}
              >
                {showBullets ? 'Show Less' : 'Show More'}
              </Typography>
              <Collapse in={showBullets}>
                <List>
                  {props.bulletPoints.map((point, index) => (
                    <ListItem key={index} sx={{ padding: 0.25, marginLeft: 2 }}>
                      <FiberManualRecordSharp
                        fontSize='inherit'
                        sx={{
                          color: theme.palette.primary.main,
                          marginRight: 1,
                        }}
                      />
                      {/* <ListItemText primary={point} sx={{ fontSize: '0.875rem' }} /> */}
                      <Typography
                        gutterBottom
                        sx={{
                          fontSize: { sm: 14, xs: 12 },
                        }}
                      >
                        {point}
                      </Typography>
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </>
          )}
        </Typography>
      )}
    </Box>
  )
}

// import React from 'react';
// import { Typography, Box, List, ListItem, ListItemText, ListItemIcon, useTheme } from '@mui/material';
// import { FiberManualRecordSharp } from '@mui/icons-material';

// interface PageTitleProps {
//     title: string;
//     subtitle?: string;
//     bulletPoints?: string[];
// }

// export const PageTitle = (props: PageTitleProps) => {

//     const theme = useTheme();

//     return (
//         <Box sx={{ marginBottom: 1 }}>
//             <Typography variant="h4" component="h1" gutterBottom>
//                 {props.title}
//             </Typography>
//             {props.subtitle && (
//                 <Typography variant="subtitle1" gutterBottom>
//                     {props.subtitle}
//                 </Typography>
//             )}
//             {props.bulletPoints && (
//                 <List>
//                     {props.bulletPoints.map((point, index) => (
//                         <ListItem key={index} sx={{marginTop: '-20px'}}>
//                             <ListItemIcon sx={{ color: theme.palette.primary.main, fontSize: 'small' }}>
//                             <FiberManualRecordSharp fontSize="inherit" />
//                             </ListItemIcon>
//                             <Typography variant="body2" gutterBottom>
//                                 {point}
//                             </Typography>
//                         </ListItem>
//                     ))}
//                 </List>
//             )}
//         </Box>
//     );
// };
