import React, { useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  Link,
  Grid,
  Box,
} from '@mui/material'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useAuth } from 'contexts/AuthContext'
// import { VerificationSuccessDialog } from './components/VerificationSuccessDialog';
import AuthLayout from 'layouts/AuthLayout'
import { authTextFieldStyle } from 'theme'

// TODO: Re-implemnt resend verification email @chaseleto
// import { resendVerificationEmail } from "core/api/users";
import { FcGoogle } from 'react-icons/fc'
import { logHyperDxAction } from 'core/api/hyperdx/logHyperDxAction'
const Login = () => {
  const navigate = useNavigate()
  const {
    loginWithEmail,
    loginWithGoogle,
    setIsAuthenticated,
    // setUserInfo,
    isVerified,
    // handleVerifyActionCode
  } = useAuth()
  const [openDialog, setOpenDialog] = useState(false)
  const [resetToken, setResetToken] = useState('')
  const [dialogContent, setDialogContent] = useState({
    title: '',
    body: '',
    button: '',
    resendVerification: '',
    resetPassword: '',
    emailVerified: '',
  })

  const [searchParams] = useSearchParams()

  const token = searchParams.get('oobCode')
  const mode = searchParams.get('mode')

  const verifyEmailOnBackend = async () => {
    console.log(token)
    const response = await fetch(`https://api.optictruckworks.com/api/action?mode=verifyEmail&oobCode=${token}`)
    // const response = await fetch(`http://localhost:3001/api/action?mode=verifyEmail&oobCode=${token}`)

    console.log('verify email on abckend response', response)

    if (response.status === 200) {
      return true
    } else {
      return false
    }
  }

  const checkForEmailVerification = async () => {
    if (token) {
      setResetToken(token)
      if (mode === 'verifyEmail') {
        // const response = await handleVerifyActionCode(token);
        const res = await verifyEmailOnBackend()
        if (res) {
          setDialogContent({
            title: 'Email Verified',
            body: `Your email has been verified. You can now login.`,
            button: 'Ok',
            resendVerification: '',
            resetPassword: '',
            emailVerified: '',
          })
          setOpenDialog(true)
        }
      }
    }
  }

  useEffect(() => {
    checkForEmailVerification()
  }, [token])

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const formData = new FormData(event.currentTarget)
    const credentials = {
      email: formData.get('email') as string,
      password: formData.get('password') as string,
    }

    const response = await loginWithEmail(credentials)


    logHyperDxAction({ action: 'login', data: { email: credentials.email } })
    

    if (response && isVerified()) {
      console.log('Logging User in.')
      // setIsAuthenticated(true);
    } else if (!isVerified()) {
      setDialogContent({
        title: 'Your email is not verified.',
        body: `Please verify your email to login.`,
        button: 'Ok',
        resendVerification: '',
        resetPassword: '',
        emailVerified: '',
      })
      setOpenDialog(true)
    } else if (response === false) {
      setDialogContent({
        title: 'Incorrect username or password.',
        body: `Please make sure you entered your username and password correctly.`,
        button: 'Ok',
        resendVerification: '',
        resetPassword: '',
        emailVerified: '',
      })
      setOpenDialog(true)
    }
  }

  const openLoginWithGooglePopup = async () => {
    const response = await loginWithGoogle()
    if (response && isVerified()) {
      setIsAuthenticated(true)
      navigate('/' + response.user_type)
    } else {
      setDialogContent({
        title: 'Error logging in with Google. ',
        body: `Please contact admin@freetech.co to resolve this issue.`,
        button: 'Ok',
        resendVerification: '',
        resetPassword: '',
        emailVerified: '',
      })
      setOpenDialog(true)
    }
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
    navigate('/login')
  }

  const navigateToResetPassword = async () => {
    setOpenDialog(false)
    navigate('/reset-password?token=' + resetToken)
  }

  const LoginDialog = () => {
    return (
      <>
        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>{dialogContent.title}</DialogTitle>
          <DialogContent>
            <Typography>{dialogContent.body}</Typography>
          </DialogContent>
          <DialogActions>
            {dialogContent.emailVerified && (
              <Button onClick={handleCloseDialog} color='primary'>
                {dialogContent.button}
              </Button>
            )}
            {!dialogContent.resetPassword && (
              <Button onClick={handleCloseDialog} color='primary'>
                {dialogContent.button}
              </Button>
            )}
            {dialogContent.resendVerification && (
              <Button onClick={navigateToResetPassword} color='primary'>
                {dialogContent.resendVerification}
              </Button>
            )}
            {dialogContent.resetPassword && (
              <Button onClick={navigateToResetPassword} color='primary'>
                {dialogContent.button}
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </>
    )
  }

  return (
    <AuthLayout>
      {/* Dialogs */}
      {/* <AuthErrorDialog /> */}
      <LoginDialog />

      {/* Login Form */}
      <Box component='form' onSubmit={handleSubmit} noValidate marginTop={1}>
        <TextField
          required
          fullWidth
          margin='normal'
          id='email'
          label='Email Address'
          name='email'
          autoComplete='email'
          sx={authTextFieldStyle}
          autoFocus
        />
        <TextField
          required
          fullWidth
          margin='normal'
          name='password'
          label='Password'
          type='password'
          id='password'
          autoComplete='current-password'
          sx={authTextFieldStyle}
        />

        {/* <FormControlLabel
          control={<Checkbox value="remember" color="secondary" />}
          label="Remember me"
        /> */}

        <Button fullWidth type='submit' variant='contained' sx={{ mt: 1, mb: 2 }}>
          {' '}
          {/* Increased the bottom margin */}
          Sign In
        </Button>

        <Button
          fullWidth
          variant='contained'
          sx={{
            backgroundColor: '#4285F4',
            color: 'white',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: '#357ae8',
            },
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mt: 1,
            mb: 1,
          }}
          startIcon={<FcGoogle />}
          onClick={() => openLoginWithGooglePopup()}
        >
          Login with Google
        </Button>

        {/* Links */}
        <Grid container>
          <Grid item xs>
            <Link href='/forgot-password' variant='body2'>
              {'Forgot password?'}
            </Link>
          </Grid>
          <Grid item>
            <Link href='/signup' variant='body2'>
              {"Don't have an account? Sign Up"}
            </Link>
          </Grid>
        </Grid>
      </Box>
    </AuthLayout>
  )
}

export default Login
