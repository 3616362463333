import React, { useEffect, useState } from 'react'
import { Dialog, Typography, Box } from '@mui/material'
import { submitApplicationCorrections } from 'core/api/application/client'

interface CorrectionsDialogProps {
  open: boolean
  onClose: () => void
  application: Application
  documentUrl: string
}

export const CorrectionsDialog = ({ open, onClose, application, documentUrl }: CorrectionsDialogProps) => {
  const [pandaPayload, setPandaPayload] = useState<PandaDocument | null>(null)
  const [formCompleted, setFormCompleted] = useState(false)

  useEffect(() => {
    const handleMessage = (event: { data: { type: string; payload: PandaDocument } }) => {
      const { type, payload } = event.data || {}
      if (type === 'embed.form.completed') {
        setPandaPayload(payload)
        setFormCompleted(false)
      }
    }

    window.addEventListener('message', handleMessage)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [])

  useEffect(() => {
    if (pandaPayload) {
      // const pandadata = {
      //     document_uuid: pandaPayload.document.id,
      //     date_completed: pandaPayload.document.date_completed,
      //     template_uuid: pandaPayload.document.created_from_template.id,
      // };

      onClose()

      submitApplicationCorrections(application.application_id).then(res => {
        if (res.status === 200) {
          // TODO: success message
          console.log('Application corrections submitted successfully')
        } else {
          console.log('Application corrections failed to submit!')
          // TODO: handle failure
        }
      })
    }
  }, [pandaPayload])

  return (
    <>
      {!formCompleted && (
        <Dialog
          open={open}
          onClose={onClose}
          PaperProps={{
            style: {
              height: '100%',
              maxHeight: 'calc(100vh - 64px)', // Adjust as needed
              overflowY: 'hidden', // Prevents double scrolling
            },
          }}
          fullWidth
          maxWidth='lg'
        >
          <Box marginLeft={5} marginTop={5}>
            <Typography variant='h4' component='h1'>
              Your Application has been viewed by an admin and they have requested some corrections.
            </Typography>
            <Typography marginTop={2} variant='h6' component='h1'>
              Correction Notes:
            </Typography>
            <Typography marginBottom={4}>{application && application.correction_notes}</Typography>
          </Box>

          {documentUrl && (
            <iframe
              src={documentUrl}
              style={{
                height: 'calc(100vh - 64px)', // Adjust as needed
                width: '100%',
                border: 'none',
              }}
              allowFullScreen
            />
          )}
        </Dialog>
      )}
    </>
  )
}
