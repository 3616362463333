import React from 'react'
import { Dialog, DialogTitle, DialogContent, Button, Typography, Box } from '@mui/material'

interface ViewAffiliateDetailsDialogProps {
  open: boolean
  onClose: () => void
  affiliate: AffiliateInfo
}

const ViewAffiliateDetailsDialog = ({ open, onClose, affiliate }: ViewAffiliateDetailsDialogProps) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth='sm'>
      <DialogTitle>Affiliate Details</DialogTitle>
      <DialogContent>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <Typography variant='subtitle1'>
            Name:{' '}
            <Typography variant='body2' component='span'>
              {affiliate?.first_name} + {affiliate?.last_name}
            </Typography>
          </Typography>
          <Typography variant='subtitle1'>
            Email:{' '}
            <Typography variant='body2' component='span'>
              {affiliate?.email}
            </Typography>
          </Typography>
          <Typography variant='subtitle1'>
            Phone:{' '}
            <Typography variant='body2' component='span'>
              {affiliate?.phone}
            </Typography>
          </Typography>
          <Typography variant='subtitle1'>
            Company Name:{' '}
            <Typography variant='body2' component='span'>
              {affiliate?.company_name}
            </Typography>
          </Typography>
          <Typography variant='subtitle1'>
            Entity Structure:{' '}
            <Typography variant='body2' component='span'>
              {affiliate?.entity_structure}
            </Typography>
          </Typography>
          <Typography variant='subtitle1'>
            Location:{' '}
            <Typography variant='body2' component='span'>
              {affiliate?.city}, {affiliate?.state}
            </Typography>
          </Typography>
          <Typography variant='subtitle1'>
            Mailing Address:{' '}
            <Typography variant='body2' component='span'>
              {affiliate?.mailing_address}
            </Typography>
          </Typography>
          <Typography variant='subtitle1'>
            T-Shirt Size:{' '}
            <Typography variant='body2' component='span'>
              {affiliate?.tshirt_size}
            </Typography>
          </Typography>
          <Typography variant='subtitle1'>
            Years in Business:{' '}
            <Typography variant='body2' component='span'>
              {affiliate?.years_in_business}
            </Typography>
          </Typography>
          <Typography variant='subtitle1'>
            Status:{' '}
            <Typography variant='body2' component='span'>
              {affiliate?.status}
            </Typography>
          </Typography>
        </Box>
      </DialogContent>
      <Box textAlign='center' m={2}>
        <Button onClick={onClose}>Close</Button>
      </Box>
    </Dialog>
  )
}

export default ViewAffiliateDetailsDialog
