import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import SaveIcon from '@mui/icons-material/Save'

import { Box, Button } from '@mui/material'
import { GridRowId, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid'
import React from 'react'

export interface SelectionButtonT {
  icon: React.JSX.Element
  label: string
  onClick: (ids: GridRowId[] | string[]) => void
}

interface EditToolbarProps {
  collectionName: string
  onCreateRow: () => void
  onDeleteRows: (selectedRowIds: GridRowId[]) => void
  onSaveRows: () => void
  search: boolean
  create: boolean
  deleteable: boolean
  multiDeleteable?: boolean
  selectedRows: GridRowId[]
  customToolbarSelectionButtons?: SelectionButtonT[]
}

const DataGridToolBar: React.FC<EditToolbarProps> = ({
  onCreateRow,
  onDeleteRows,
  onSaveRows,
  search,
  create,
  multiDeleteable,
  selectedRows,
  customToolbarSelectionButtons,
}) => {
  return (
    <GridToolbarContainer
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {create && (
        <Button color='primary' size='small' startIcon={<AddIcon />} onClick={onCreateRow} sx={{ marginRight: 1 }}>
          Add
        </Button>
      )}
      <Button color='primary' size='small' startIcon={<SaveIcon />} onClick={onSaveRows} sx={{ marginRight: 1 }} />

      {selectedRows.length > 0 && multiDeleteable && (
        <Button startIcon={<DeleteIcon />} onClick={() => onDeleteRows(selectedRows)} sx={{ color: 'error.main' }}>
          Delete
        </Button>
      )}

      {selectedRows.length > 0 &&
        customToolbarSelectionButtons &&
        customToolbarSelectionButtons.map((button, index: number) => (
          <Button key={index} startIcon={button.icon} onClick={() => button.onClick(selectedRows)}>
            {button.label}
          </Button>
        ))}

      {search && (
        <Box sx={{ marginLeft: 'auto' }}>
          <GridToolbarQuickFilter />
        </Box>
      )}
    </GridToolbarContainer>
  )
}

export default DataGridToolBar
