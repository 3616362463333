import React, { useState, useEffect } from 'react'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  IconButton,
  Typography,
  Divider,
  Box,
  Alert,
  Collapse,
  CircularProgress,
} from '@mui/material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import CloseIcon from '@mui/icons-material/Close'

import {
  submitTruckDebtOverhaulSubmission,
  associateTrucksToSubmission,
  getTruckDebtOverhaulSubmissionInfo,
  editTruckDebtOverhaulSubmission,
} from 'core/api/application/client'
import SizeDropdown from './SizeDropdown'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import dayjs from 'dayjs'
import { DateField } from '@mui/x-date-pickers/DateField'

interface NewDebtOverHaulDialogProps {
  isOpen: boolean
  onClose: () => void
  isEditing: boolean
  submissionId: number
  setEditing: (editing: { isEditing: boolean; submissionId: number }) => void
}

const NewDebtOverhaulDialog = ({
  isOpen,
  onClose,
  isEditing,
  submissionId,
  setEditing,
}: NewDebtOverHaulDialogProps) => {
  const [submissionInfo, setSubmissionInfo] = useState<TruckDebtOverhaulSubmission>({
    current_credit_score: '',
    number_of_trucks: '',
    monthly_truck_payment: '',
  })
  const [vehicles, setVehicles] = useState<DebtOverhaulTruck[]>([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState({
    isError: false,
    errorMessage: '',
  })

  const fetchSubmissionInfo = async () => {
    setLoading(true)
    const response = await getTruckDebtOverhaulSubmissionInfo(submissionId)

    if (response['status'] === 'ok' && response['submission_info']) {
      const submissionInfo = response['submission_info']

      updateSubmissionInfo('current_credit_score', submissionInfo['current_credit_score'].toString())
      updateSubmissionInfo('number_of_trucks', submissionInfo['number_of_trucks'].toString())
      updateSubmissionInfo('monthly_truck_payment', submissionInfo['monthly_truck_payment'].toString())

      const convertedTrucks: DebtOverhaulTruck[] = submissionInfo['trucks'].map((truck: DebtOverhaulTruck) => {
        if (!truck.id) {
          return
        }

        const convertedTruck: DebtOverhaulTruck = {
          id: truck.id.toString(),
          year: truck.year.toString(),
          fuel_type: truck.fuel_type.toString(),
          VIN: truck.VIN.toString(),
          model: truck.model.toString(),
          size: truck.size.toString(),
          miles: truck.miles,
          current_payoff: truck.current_payoff.toString(),
          monthly_payment: truck.monthly_payment.toString(),
          purchase_date: truck.purchase_date.toString(),
          bank: truck.bank.toString(),
        }
        return convertedTruck
      })

      setVehicles(convertedTrucks)
    }

    setLoading(false)
  }

  const saveEditedSubmission = async () => {
    if (!validateFormInfo()) return

    const cleanVehicles = vehicles.map(vehicle => ({
      ...vehicle,
      miles: removeCommasFromString(vehicle.miles),
    }))

    setLoading(true)
    const editedSubmissionInfo = {
      submission_id: submissionId,
      current_credit_score: submissionInfo.current_credit_score,
      number_of_trucks: submissionInfo.number_of_trucks,
      monthly_truck_payment: submissionInfo.monthly_truck_payment,
      trucks: cleanVehicles,
    }

    const editResponse = await editTruckDebtOverhaulSubmission(editedSubmissionInfo)

    if (editResponse['status'] !== 'ok') {
      setError({
        isError: true,
        errorMessage: `Submission error: Failed to edit submission. Please try again or contact support.`,
      })
      return
    }

    setVehicles([])
    handleClose()
    resetSubmissionInfo()
    resetErrorState()
    setLoading(false)
  }

  useEffect(() => {
    if (isEditing && submissionId !== -1) fetchSubmissionInfo()
  }, [isEditing, submissionId])

  const updateSubmissionInfo = (field: string, value: string) => {
    setSubmissionInfo(prevState => ({
      ...prevState,
      [field]: value,
    }))
  }

  const resetSubmissionInfo = () => {
    setSubmissionInfo({
      current_credit_score: '',
      number_of_trucks: '',
      monthly_truck_payment: '',
    })
  }

  const handleVehicleChange = (index: number, field: keyof DebtOverhaulTruck, value: string) => {
    const updatedVehicles = [...vehicles]
    updatedVehicles[index][field] = value
    setVehicles(updatedVehicles)
  }

  function removeCommasFromString(numberString: string) {
    return numberString.replace(/,/g, '')
  }

  const addVehicleForm = () => {
    setVehicles([
      ...vehicles,
      {
        year: '',
        fuel_type: '',
        VIN: '',
        model: '',
        size: '',
        miles: '',
        current_payoff: '',
        monthly_payment: '',
        purchase_date: '',
        bank: '',
      },
    ])
  }

  const removeVehicleForm = () => {
    const newVehiclesArray = vehicles.slice(0, -1)
    setVehicles(newVehiclesArray)
  }

  const handleSubmit = async () => {
    setLoading(true)
    try {
      if (!validateFormInfo()) return

      const cleanVehicles = vehicles.map(vehicle => ({
        ...vehicle,
        miles: removeCommasFromString(vehicle.miles),
      }))

      const submissionResponse = await submitTruckDebtOverhaulSubmission(submissionInfo)
      if (submissionResponse['status'] === 'ok') {
        const response = await associateTrucksToSubmission(submissionResponse['submission'].id, cleanVehicles)

        if (!response) {
          setError({
            isError: true,
            errorMessage: `Submission error: Failed to associate vehicles with submission. Please try again or contact support.`,
          })
        }
      } else {
        setError({
          isError: true,
          errorMessage: `Submission error: Failed to process submission. Please try again or contact support.`,
        })
      }
      handleClose()
    } catch (error) {
      setError({
        isError: true,
        errorMessage: `Submission error: Failed to process submission. Please try again or contact support.`,
      })
    } finally {
      setLoading(false)
    }
  }

  const handleClose = () => {
    setVehicles([])
    resetSubmissionInfo()
    resetErrorState()
    setEditing({
      isEditing: false,
      submissionId: -1,
    })
    onClose()
  }

  const validateFormInfo = () => {
    if (!validateCreditScore(submissionInfo.current_credit_score)) {
      setError({ isError: true, errorMessage: 'Please ensure you have entered a valid credit score.' })
      return false
    }

    if (!validateMonetaryInput(submissionInfo.monthly_truck_payment)) {
      setError({
        isError: true,
        errorMessage: 'Validation error: Please ensure the Total Monthly Truck Payment is a valid monetary amount.',
      })
      return false
    }

    if (parseInt(submissionInfo.number_of_trucks) !== vehicles.length) {
      setError({
        isError: true,
        errorMessage:
          'Please ensure the Number of Trucks field matches the number of vehicles you have added to the form.',
      })
      return false
    }

    for (let i = 0; i < vehicles.length; i++) {
      const truck = vehicles[i]

      const truckNumber = i + 1
      if (!validateYear(truck.year)) {
        setError({
          isError: true,
          errorMessage: `Validation error: Please ensure the Year for Truck ${truckNumber} is a valid year.`,
        })
        return false
      } else if (!validateVIN(truck.VIN)) {
        setError({
          isError: true,
          errorMessage: `Validation error: Please ensure the VIN for truck ${truckNumber} is a 17-character number.`,
        })
        return false
      } else if (!validateMiles(truck.miles)) {
        setError({
          isError: true,
          errorMessage: `Validation error: Please ensure the Miles for Truck ${truckNumber} is a valid number.`,
        })
        return false
      } else if (!validatePurchaseDate(truck.purchase_date)) {
        setError({
          isError: true,
          errorMessage: `Validation error: Please ensure the Purchase Date for truck ${truckNumber} is a valid date in the format MM/YYYY.`,
        })
        return false
      } else if (!validateMonetaryInput(truck.current_payoff)) {
        setError({
          isError: true,
          errorMessage: `Validation error: Please ensure the Current Payoff for truck ${truckNumber} is a valid monetary amount.`,
        })
        return false
      } else if (!validateMonetaryInput(truck.monthly_payment)) {
        setError({
          isError: true,
          errorMessage: `Validation error: Please ensure the Monthly Payment for truck ${truckNumber} is a valid monetary amount.`,
        })
        return false
      } else if (
        isEmptyString(truck.fuel_type) ||
        isEmptyString(truck.model) ||
        isEmptyString(truck.bank) ||
        isEmptyString(truck.size)
      ) {
        setError({
          isError: true,
          errorMessage: `Validation error: Please ensure Fuel Type, Model, Size or Bank are not empty for Truck ${truckNumber}.`,
        })
        return false
      }
    }

    return true
  }

  const validateCreditScore = (creditScore: string) => {
    if (creditScore === '') return false

    const regex = /^(?:[3-7]\d{2}|8[0-4]\d|850)$/

    return regex.test(creditScore)
  }

  const validateYear = (yearString: string) => {
    if (yearString === '' || !/^\d+$/.test(yearString)) {
      return false
    }

    const year = parseInt(yearString, 10)

    const currentYear = new Date().getFullYear()
    const minYear = 1950

    return year >= minYear && year <= currentYear
  }

  const validateVIN = (VIN: string) => {
    if (VIN === '' || VIN.length < 17) return false

    return true
  }

  const validateMiles = (miles: string) => {
    const regex = /^(\d{1,3}(,\d{3})*)?$/

    return regex.test(miles)
  }

  const validatePurchaseDate = (purchaseDate: string) => {
    if (purchaseDate === '' || !/^\d{2}\/\d{4}$/.test(purchaseDate)) {
      return false
    }

    const [month, year] = purchaseDate.split('/')
    const parsedMonth = parseInt(month, 10)
    const parsedYear = parseInt(year, 10)

    const currentYear = new Date().getFullYear()
    const minYear = 1950
    return (
      parsedMonth >= 1 &&
      parsedMonth <= 12 &&
      !isNaN(parsedYear) &&
      parsedYear >= minYear &&
      parsedYear <= currentYear
    )
  }

  const validateMonetaryInput = (monetaryInput: string) => {
    if (!monetaryInput.trim()) {
      return false
    }

    const regex = /^\d+(\.\d{1,2})?$/

    return regex.test(monetaryInput)
  }

  const isEmptyString = (string: string) => {
    if (string === '') return true

    return false
  }

  const resetErrorState = () => {
    setError({ isError: false, errorMessage: '' })
  }

  const loadingStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100px',
  }

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        handleClose()
      }}
      maxWidth='lg'
      fullWidth
    >
      <DialogTitle fontSize={18} fontWeight={'bold'}>
        Debt Overhaul Info
      </DialogTitle>
      <DialogContent sx={loading ? loadingStyles : null}>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <Grid container spacing={2} alignItems='center' sx={{ marginTop: 1, marginBottom: 2 }}>
              <Grid item xs={12} sm={2} sx={{ marginRight: { xs: 0, sm: 25 } }}>
                <TextField
                  fullWidth
                  label='Current Credit Score'
                  value={submissionInfo.current_credit_score}
                  onChange={e => updateSubmissionInfo('current_credit_score', e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                {/* <Typography>{`Number of Vehicles: ${submissionInfo.number_of_trucks}`}</Typography> */}
                <TextField
                  fullWidth
                  label='Number of Trucks'
                  value={submissionInfo.number_of_trucks}
                  onChange={e => updateSubmissionInfo('number_of_trucks', e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  label='Total Monthly Truck Payment ($)'
                  value={submissionInfo.monthly_truck_payment}
                  onChange={e => updateSubmissionInfo('monthly_truck_payment', e.target.value)}
                />
              </Grid>
            </Grid>
            <DialogTitle fontSize={18} fontWeight={'bold'}>
              Vehicles:
            </DialogTitle>
            {vehicles.map((vehicle, index) => (
              <Box key={index}>
                <Typography variant='h6' sx={{ mt: 2 }}>
                  Vehicle {index + 1}
                </Typography>
                <Grid container spacing={2} alignItems='center'>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={2}>
                        <TextField
                          fullWidth
                          label='Year'
                          value={vehicle.year}
                          inputProps={{
                            inputMode: 'numeric',
                            maxLength: 4,
                          }}
                          onChange={e => handleVehicleChange(index, 'year', e.target.value)}
                          color={validateYear(vehicle.year) ? 'primary' : 'error'}
                        />
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <TextField
                          fullWidth
                          label='Fuel Type'
                          value={vehicle.fuel_type}
                          onChange={e => handleVehicleChange(index, 'fuel_type', e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <SizeDropdown index={index} size={vehicle.size} setSize={handleVehicleChange} />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <TextField
                          fullWidth
                          label='VIN'
                          value={vehicle.VIN}
                          onChange={e => handleVehicleChange(index, 'VIN', e.target.value.toUpperCase())}
                          color={validateVIN(vehicle.VIN) ? 'primary' : 'error'}
                          inputProps={{ autoCapitalize: 'characters' }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <TextField
                          fullWidth
                          label='Model'
                          value={vehicle.model}
                          onChange={e => handleVehicleChange(index, 'model', e.target.value)}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ mt: 2 }}>
                      <Grid item xs={12} sm={2}>
                        <TextField
                          fullWidth
                          label='Miles'
                          value={vehicle.miles}
                          onChange={e => handleVehicleChange(index, 'miles', e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <TextField
                          fullWidth
                          label='Current Payoff ($)'
                          value={vehicle.current_payoff}
                          onChange={e => handleVehicleChange(index, 'current_payoff', e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <TextField
                          fullWidth
                          label='Monthly Payment ($)'
                          value={vehicle.monthly_payment}
                          onChange={e => {
                            handleVehicleChange(index, 'monthly_payment', e.target.value)
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateField
                            fullWidth
                            label='Purchase Date (MM/YYYY)'
                            maxDate={dayjs().startOf('month')}
                            format='MM/YYYY'
                            clearable
                            onChange={date => {
                              const formattedDate = date ? date.format('MM/YYYY') : ''
                              handleVehicleChange(index, 'purchase_date', formattedDate)
                            }}
                            value={vehicle.purchase_date ? dayjs(vehicle.purchase_date, 'MM/YYYY') : null}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <TextField
                          fullWidth
                          label='Bank'
                          value={vehicle.bank}
                          onChange={e => handleVehicleChange(index, 'bank', e.target.value)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                {vehicles.length - 1 !== index && <Divider sx={{ mt: 2, mb: 2 }} />}
              </Box>
            ))}
            <IconButton
              onClick={() => {
                addVehicleForm()
              }}
              color='primary'
            >
              <AddCircleOutlineIcon />
            </IconButton>
            {vehicles.length > 0 && (
              <IconButton
                onClick={() => {
                  removeVehicleForm()
                }}
                color='primary'
              >
                <RemoveCircleOutlineIcon />
              </IconButton>
            )}
            {error.isError && (
              <Collapse in={error.isError}>
                <Alert
                  severity='error'
                  action={
                    <IconButton
                      aria-label='close'
                      color='inherit'
                      size='small'
                      onClick={() => {
                        resetErrorState()
                      }}
                    >
                      <CloseIcon fontSize='inherit' />
                    </IconButton>
                  }
                  sx={{ mb: 2 }}
                >
                  {error.errorMessage}
                </Alert>
              </Collapse>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleClose()
            resetSubmissionInfo()
            resetErrorState()
          }}
          color='primary'
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            isEditing ? saveEditedSubmission() : handleSubmit()
          }}
          color='primary'
          disabled={loading || vehicles.length < 1}
        >
          {isEditing ? 'Save' : 'Submit'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default NewDebtOverhaulDialog
