import React from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import { AuthRoute, DealerRoute, ClientRoute, BankRoute, SuperUserRoute, ProtectedRoute } from './components'
import { NotFound, IsLoading, AccountDeactivated, NeedVerification } from 'components'
import { useAuth } from 'contexts/AuthContext'
import { Login, Signup, ForgotPassword } from 'views/Auth'
import { Profile, SupportRequest } from 'views/Shared'
import {
  SuperAdminHome,
  HubspotDash,
  ApplicationManagement,
  UserManagement,
  DepreciationCalculator,
  AffiliateManagement,
  TruckDebtOverhaulManagement,
  VINDecoder,
  InventoryManagement,
} from 'views/SuperAdmin'
import { ClientHome, ApplicationNew, BecomeAnAffiliate, AffiliateDashboard, DebtOverhaul } from 'views/Client'
import { BankHome } from 'views/Bank'
import { DealerHome } from 'views/Dealer'
import ResetPassword from 'views/Auth/ResetPassword/ResetPassword'
import SubmissionManagement from 'views/SuperAdmin/Management/SubmissionManagement'

export const AppRoutes = () => {
  const { isLoading, userInfo, isVerified } = useAuth()

  if (isLoading) {
    return <IsLoading />
  }

  if (userInfo?.is_active === false) {
    return <AccountDeactivated />
  }

  if (!isVerified() && userInfo) {
    return <NeedVerification />
  }

  const AuthRoutes = () => {
    return (
      <>
        <Route
          path='/login'
          element={
            <AuthRoute>
              <Login />
            </AuthRoute>
          }
        />

        <Route
          path='/signup'
          element={
            <AuthRoute>
              <Signup />
            </AuthRoute>
          }
        />

        <Route
          path='/forgot-password'
          element={
            <AuthRoute>
              <ForgotPassword />
            </AuthRoute>
          }
        />

        {/* TODO: Create an /auth/action handler based on mode @chaseleto */}
        <Route
          path='/__/auth/action'
          element={
            <AuthRoute>
              <ResetPassword />
            </AuthRoute>
          }
        />
      </>
    )
  }

  const DealerRoutes = () => {
    return (
      <>
        <Route
          path='/dealer'
          element={
            <DealerRoute>
              <DealerHome />
            </DealerRoute>
          }
        />
      </>
    )
  }

  const ClientRoutes = () => {
    return (
      <>
        <Route
          path='/client'
          element={
            <ClientRoute>
              <ClientHome />
            </ClientRoute>
          }
        />

        <Route
          path='/client/application'
          element={
            <ClientRoute>
              <ApplicationNew />
            </ClientRoute>
          }
        />

        <Route
          path='/client/become-an-affiliate'
          element={
            <ClientRoute>
              <BecomeAnAffiliate />
            </ClientRoute>
          }
        />

        <Route
          path='/client/affiliate-dashboard'
          element={
            <ClientRoute>
              <AffiliateDashboard />
            </ClientRoute>
          }
        />

        <Route
          path='/client/debt-overhaul'
          element={
            <ClientRoute>
              <DebtOverhaul />
            </ClientRoute>
          }
        />
      </>
    )
  }

  const BankRoutes = () => {
    return (
      <>
        <Route
          path='/bankAgent'
          element={
            <BankRoute>
              <BankHome />
            </BankRoute>
          }
        />
      </>
    )
  }

  const SuperUserRoutes = () => {
    return (
      <>
        <Route
          path='/admin'
          element={
            <SuperUserRoute>
              <SuperAdminHome />
            </SuperUserRoute>
          }
        />

        <Route
          path='/admin/hubspot-integration'
          element={
            <SuperUserRoute>
              <HubspotDash />
            </SuperUserRoute>
          }
        />

        <Route
          path='/admin/depreciation-calculator'
          element={
            <SuperUserRoute>
              <DepreciationCalculator />
            </SuperUserRoute>
          }
        />
        <Route
          path='/admin/vin-decoder'
          element={
            <SuperUserRoute>
              <VINDecoder />
            </SuperUserRoute>
          }
        />
        <Route
          path='/admin/application-management'
          element={
            <SuperUserRoute>
              <ApplicationManagement />
            </SuperUserRoute>
          }
        />

        <Route
          path='/admin/user-management'
          element={
            <SuperUserRoute>
              <UserManagement />
            </SuperUserRoute>
          }
        />

        <Route
          path='/admin/submission-management'
          element={
            <SuperUserRoute>
              <SubmissionManagement />
            </SuperUserRoute>
          }
        />

        <Route
          path='/admin/affiliate-management'
          element={
            <SuperUserRoute>
              <AffiliateManagement />
            </SuperUserRoute>
          }
        />

        <Route
          path='/admin/truck-debt-overhaul-management'
          element={
            <SuperUserRoute>
              <TruckDebtOverhaulManagement />
            </SuperUserRoute>
          }
        />

        <Route
          path='/admin/shield-inventory-management'
          element={
            <SuperUserRoute>
              <InventoryManagement inventoryCollectionName='shield_inventory' />
            </SuperUserRoute>
          }
        />
        <Route
          path='/admin/optic-inventory-management'
          element={
            <SuperUserRoute>
              <InventoryManagement inventoryCollectionName='master_inventory' />
            </SuperUserRoute>
          }
        />
      </>
    )
  }

  const SharedRoutes = () => {
    return (
      <>
        {/* Profile */}
        <Route
          path='/profile'
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />

        {/* Support Request */}
        <Route
          path='/support-request'
          element={
            <ProtectedRoute>
              <SupportRequest />
            </ProtectedRoute>
          }
        />

        {/* Not Found - other pages redirect here */}
        <Route
          path='/not-found'
          element={
            <ProtectedRoute>
              <NotFound />
            </ProtectedRoute>
          }
        />

        {/* Not Found - other pages redirect here */}
        <Route
          path='/account-deactivated'
          element={
            <ProtectedRoute>
              <AccountDeactivated />
            </ProtectedRoute>
          }
        />

        {/* Redirect * to NotFound to hide SuperUser Routes  */}
        <Route
          path='*'
          element={
            <ProtectedRoute>
              <Navigate to='/not-found' replace />
            </ProtectedRoute>
          }
        />
      </>
    )
  }

  return (
    <Router>
      <Routes>
        {AuthRoutes()}

        {ClientRoutes()}

        {DealerRoutes()}

        {BankRoutes()}

        {SharedRoutes()}

        {SuperUserRoutes()}
      </Routes>
    </Router>
  )
}
