import React, { useState, useEffect, useCallback, useRef } from 'react'
import { GridColDef, GridActionsCellItem } from '@mui/x-data-grid'
import MainLayout from 'layouts/MainLayout'
import DataGridFirestoreCRUD, { ColumnFunctionsType } from 'components/DataGridFirestoreCRUD'
import { CreateUser } from './components/CreateUser'
import { deleteFirebaseUser } from 'core/api/users'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { updateUser } from 'core/api/users'
import { AddComment, NotificationImportant } from '@mui/icons-material'
import { PageTitle } from 'components/PageTitle'
import { resendVerificationEmail } from 'core/api/users'
import { useAuth } from 'contexts/AuthContext'
import { getBanksIdAndNames } from 'core/api/banks/getBankIdAndNames'
import { updateUserBank } from 'core/api/users/updateUserBank'

const booleanOptions = ['true', 'false']

const UserManagement = () => {
  const [open, setOpen] = useState(false)
  const [selectedUser, setSelectedUser] = useState<UserInfoType | null>(null)
  const { userInfo } = useAuth()
  const notesRef = useRef('')
  const [bankOptions, setBankOptions] = useState<{ id: string, name: string }[]>([])
  const [bankIdToNameMap, setBankIdToNameMap] = useState<{ [id: string]: string }>({})

  let userTypeOptions: string[] = []
  if (userInfo?.user_type === 'admin') {
    userTypeOptions = ['admin', 'bankAgent', 'client', 'dealer', 'babyAdmin']
  } else {
    userTypeOptions = ['bankAgent', 'client', 'dealer', 'babyAdmin']
  }

  useEffect(() => {
    const fetchBanks = async () => {
      const banks = await getBanksIdAndNames()
      const bankOptions: { id: string, name: string }[] = Object.keys(banks).map(id => ({ id, name: banks[id] }))
      setBankOptions(bankOptions)
      setBankIdToNameMap(banks)
    }

    fetchBanks()
  }, [])

  const handleOpen = useCallback((user: UserInfoType) => {
    setSelectedUser(prevState => ({ ...prevState, ...user }))
    notesRef.current = user.notes || ''
    setOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const handleSaveNotes = useCallback(async () => {
    if (selectedUser) {
      await updateUser(selectedUser.id, { notes: notesRef.current })
    }
    handleClose()
  }, [selectedUser, handleClose])

  const handleChangeNotes = (event: { target: { value: string } }) => {
    notesRef.current = event.target.value // Update ref value without causing re-renders
  }

  const handleNotifyClick = (row: { email: string }) => async () => {
    const { email } = row

    resendVerificationEmail(email)
    // open dialog for success or not
  }
  const handleBankChange = async (id: string, value: string) => {
    await updateUserBank(id, value)
  }

  const columnFunctions: ColumnFunctionsType = {
    bank_id: handleBankChange,
  }

  const columns: GridColDef[] = [
    {
      field: 'first_name',
      headerName: 'First Name',
      width: 150,
      editable: true,
    },
    { field: 'last_name', headerName: 'Last Name', width: 150, editable: true },
    {
      field: 'user_type',
      headerName: 'User Type',
      width: 100,
      editable: true,
      type: 'singleSelect',
      valueOptions: userTypeOptions,
    },
    {
      field: 'bank_id',
      headerName: 'Bank',
      width: 200,
      editable: true,
      headerAlign: 'center',
      type: 'singleSelect',
      valueOptions: bankOptions.map(bank => ({ value: bank.id, label: bank.name })),
      valueFormatter: (value) => {
        if (value as string) {
          return bankIdToNameMap[value as string]
        }
      },
    },    
    { field: 'email', headerName: 'Email', width: 300, editable: false, headerAlign: 'center' },
    { field: 'phone', headerName: 'Phone', width: 150, editable: true },
    {
      field: 'is_active',
      headerName: 'Active',
      width: 75,
      editable: true,
      type: 'singleSelect',
      valueOptions: booleanOptions,
    },
    {
      field: 'email_verified',
      headerName: 'Email Verified',
      width: 130,
      editable: true,
      type: 'singleSelect',
      valueOptions: booleanOptions,
    },
    {
      field: 'Resend Email',
      type: 'actions',
      headerName: 'Resend Verification',
      editable: false,
      width: 150,
      getActions: params => [
        <GridActionsCellItem
          key={params.row.id}
          icon={<NotificationImportant />}
          label='Send Reminder Email'
          onClick={() => handleNotifyClick(params.row)()}
          color='inherit'
          disabled={params.row.email_verified === true}
        />,
      ],
    },
    {
      field: 'add_notes',
      type: 'actions',
      headerName: 'Notes',
      width: 80,
      getActions: params => [
        <GridActionsCellItem
          key={params.row.id}
          icon={<AddComment />}
          label='Add Notes'
          onClick={() => handleOpen(params.row)}
          color='inherit'
        />,
      ],
    },
  ]

  const deleteUser = async (userId: string) => {
    console.log('Deleting user', userId)
    try {
      await deleteFirebaseUser(userId)
    } catch (error) {
      console.error('Failed to delete user', error)
    }
  }

  return (
    <MainLayout>
      <PageTitle
        title='User Management'
        subtitle='This page allows you to create, update, or delete portal users.'
        bulletPoints={[
          'Double-click the row to start editing a user, then click the save icon that appears on the right side.',
          "Use the 'Remind' icon allows you to resend a verification email to a user.",
          "Share user notes with the team by clicking the 'Add Notes' icon.",
        ]}
      />

      <CreateUser />

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Notes</DialogTitle>
        <DialogContent sx={{ width: '550px' }}>
          <TextField
            autoFocus
            margin='dense'
            id='notes'
            label='Notes'
            type='text'
            fullWidth
            variant='outlined'
            multiline
            rows={10}
            defaultValue={notesRef.current}
            onChange={handleChangeNotes}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSaveNotes}>Save</Button>
        </DialogActions>
      </Dialog>

      <DataGridFirestoreCRUD
        columns={columns}
        collectionName='users'
        editable={true}
        deleteable={true}
        create={false}
        search={true}
        // filters={filters}
        onDeleteClick={(id) => deleteUser(id)}
        customColumnSaveFunctions={columnFunctions}
      />
    </MainLayout>
  )
}

export default UserManagement
