import * as React from 'react'
import * as ReactDOM from 'react-dom/client'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import App from './App'
import theme from './theme'
const rootElement = document.getElementById('root')
const root = ReactDOM.createRoot(rootElement!)
import HyperDX from '@hyperdx/browser'

if (window.location.hostname === 'portal.optictruckworks.com') {
  HyperDX.init({
    apiKey: '010f76ed-e39b-4a1e-8a8f-909ee7b1f7d2',
    service: 'otw-portal',
    tracePropagationTargets: [/api.optictruckworks.com/i], // Set to link traces from frontend to backend requests
    consoleCapture: true, // Capture console logs (default false)
    advancedNetworkCapture: true, // Capture full HTTP request/response headers and bodies (default false)
  })
} else if (window.location.hostname === 'dev-portal.optictruckworks.com') {
  HyperDX.init({
    apiKey: '010f76ed-e39b-4a1e-8a8f-909ee7b1f7d2',
    service: 'stg-otw-portal',
    tracePropagationTargets: [/dev-api.optictruckworks.com/i], // Set to link traces from frontend to backend requests
    consoleCapture: false, // Capture console logs (default false)
    advancedNetworkCapture: false, // Capture full HTTP request/response headers and bodies (default false)
  })
}

root.render(
  <React.StrictMode>
  <ThemeProvider theme={theme}>
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    <CssBaseline />
    <App />
  </ThemeProvider>,
  </React.StrictMode>
)
