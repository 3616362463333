import { Button, Dialog, DialogContent, DialogTitle, MenuItem, Select } from '@mui/material'
import { SubmissionStatus } from 'core/types/form_submission'
import React from 'react'

interface ChangeStatusButtonProps {
  status: SubmissionStatus
  onStatusChange: (status: SubmissionStatus) => void
}

const ChangeStatusButton: React.FC<ChangeStatusButtonProps> = ({ status, onStatusChange }) => {
  const [open, setOpen] = React.useState(false)

  const submissionStatus = ['New','Completed', 'Canceled']
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const handleStatusChange = (newStatus: SubmissionStatus) => {
    onStatusChange(newStatus)
    handleClose()
  }

  return (
    <>
      <Button variant='outlined' onClick={handleOpen}>
        Change Status
      </Button>
      <Dialog fullWidth open={open} onClose={handleClose}>
        <DialogTitle>Change Status</DialogTitle>
        <DialogContent sx={{ width: '100%' }}>
          <Select value={status} onChange={e => handleStatusChange(e.target.value as SubmissionStatus)} fullWidth>
            {submissionStatus.map(s => (
              <MenuItem key={s} value={s}>
                {s}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default ChangeStatusButton
