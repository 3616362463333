import { Box, Card, CardActions, CardContent, CardHeader, CardProps, Typography } from '@mui/material'
import { ContactSubmission } from 'core/types/form_submission'
import React from 'react'

interface SubmissionFormProps extends CardProps {
  submission: ContactSubmission
  onClick: () => void
}

const ContactSubmissionCard: React.FC<SubmissionFormProps> = ({ submission, onClick, ...cardProps }) => {
  return (
    <Card {...cardProps} sx={{ minWidth: 275 }}>
      <CardHeader title={`${submission.firstName} ${submission.lastName}`} sx={{ pb: 1 }} />
      <CardContent onClick={onClick}>
        <Box display='flex' flexDirection='column' mt={1} gap={3}>
          <Box>
            <Typography variant='h6' sx={{ mb: 1 }}>
              Message
            </Typography>
            <Box ml={2} display='flex' flexDirection='column' gap={1}>
              <Typography>{submission.message}</Typography>
            </Box>
          </Box>
          <Box>
            <Typography fontWeight='medium' variant='h5' sx={{ mb: 1 }}>
              Contact Information
            </Typography>
            <Box ml={2} display='flex' flexDirection='column' gap={1}>
              <Typography>
                <strong>Email:</strong> {submission.email}
              </Typography>
              <Typography>
                <strong>Phone:</strong> {submission.phone}
              </Typography>
            </Box>
          </Box>
        </Box>
      </CardContent>
      <CardActions></CardActions>
    </Card>
  )
}

export default ContactSubmissionCard
