import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from 'contexts/AuthContext'
import { IsLoading, NotFound } from 'components'

interface ProtectedRouteProps {
  children: React.ReactNode
}

const useProtectedRoute = () => {
  const { isAuthenticated, isLoading } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login')
    }
  }, [isAuthenticated, navigate])

  return { isAuthenticated, isLoading }
}

const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const { isAuthenticated, isLoading } = useProtectedRoute()

  if (isLoading) {
    return <IsLoading />
  }

  if (!isAuthenticated) {
    return <NotFound />
  }

  return <>{children}</>
}

export default ProtectedRoute
