import { DateTime } from 'luxon';

export const formatDateToLocalTime = (dateStr: string): string => {
    try {
        // Parse the date string in UTC
        const utcDate = DateTime.fromFormat(dateStr, 'yyyy-MM-dd HH:mm:ss', { zone: 'utc' });

        if (!utcDate.isValid) {
            // If the date is not valid, return the original date string
            return dateStr;
        }

        // Convert the date to the local timezone and format it in 12-hour time
        const localDate = utcDate.setZone('local').toFormat('MM/dd/yyyy hh:mm a');

        return localDate;
    } catch (error) {
        console.error('Error parsing or formatting date:', error);
        // If there's an error, return the original date string
        return dateStr;
    }
};

