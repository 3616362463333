import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from 'contexts/AuthContext'
import { IsLoading, NotFound } from 'components'

interface BankRouteProps {
  children: React.ReactNode
}

const useBankRoute = () => {
  const { isAuthenticated, userInfo, isLoading } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login')
    } else if ((userInfo && userInfo.user_type !== 'bankAgent') || !userInfo?.user_type) {
      navigate(`/not-found`)
    }
  }, [isAuthenticated, navigate])

  return { isAuthenticated, isLoading }
}

const BankRoute = ({ children }: BankRouteProps) => {
  const { isAuthenticated, isLoading } = useBankRoute()

  if (isLoading) {
    return <IsLoading />
  }

  if (!isAuthenticated) {
    return <NotFound />
  }

  return <>{children}</>
}

export default BankRoute
