import React from 'react'
import MainLayout from 'layouts/MainLayout'
import { Typography } from '@mui/material'
import ApplicationTable from './components/ApplicationTable'

const BankHome = (): JSX.Element => {
  return (
    <MainLayout>
      <Typography>Bank Home</Typography>
      <ApplicationTable />
    </MainLayout>
  )
}

export default BankHome
