import React, { useState, useEffect } from 'react'
import { Typography, Box, Grid, TextField, Button } from '@mui/material'
import { registerAffiliate } from 'core/api/application/client'
import { MainLayout } from 'layouts'
import { useAuth } from 'contexts/AuthContext'
import { NotFound } from 'components'
import { useNavigate } from 'react-router-dom'
// import W9Form from '../AffiliateDashboard/components/W9Form';

const BecomeAnAffiliate = () => {
  const { userInfo, getUserInfo } = useAuth()
  const navigate = useNavigate()

  const [formValues, setFormValues] = useState({
    phone: '',
    city: '',
    state: '',
    mailingAddress: '',
    tshirtSize: '',
    yearsInBusiness: '',
  })

  const handleChange = (e: { target: { name: string; value: string } }) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value })
  }

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault()
    const res = await registerAffiliate(formValues)
    if (res.status === 200) {
      setRegistrationComplete(true)
    }
  }

  const [registrationComplete, setRegistrationComplete] = useState(false)

  useEffect(() => {
    if (registrationComplete) {
      const refreshUserInfo = async () => {
        const userInfo = await getUserInfo()
        if (userInfo?.affiliate_id !== null) {
          navigate('/client/affiliate-dashboard')
        }
      }

      refreshUserInfo()
    }
  }, [registrationComplete, navigate])

  if (userInfo?.affiliate_id !== null) {
    return <NotFound />
  }

  return (
    <MainLayout>
      <Typography variant='h4' gutterBottom>
        Submit an Application below to become an Optic Truck Works Affiliate!
      </Typography>

      <Typography component='p' gutterBottom>
        By joining our affiliate program, you will be able to earn commission on sales made on your converted leads.
        Once you sign up, you will be able to submit leads and earn commission on converted leads.
      </Typography>

      <Box component='form' onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id='phone'
              label='Phone Number'
              name='phone'
              value={formValues.phone}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              id='city'
              label='City'
              name='city'
              value={formValues.city}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              id='state'
              label='State'
              name='state'
              value={formValues.state}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id='mailingAddress'
              label='Mailing Address'
              name='mailingAddress'
              value={formValues.mailingAddress}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              id='tshirtSize'
              label='T-Shirt Size'
              name='tshirtSize'
              value={formValues.tshirtSize}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              id='yearsInBusiness'
              label='Years in Business'
              name='yearsInBusiness'
              type='number'
              value={formValues.yearsInBusiness}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12}>
            <Button type='submit' fullWidth variant='contained' sx={{ mt: 3, mb: 2 }}>
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
    </MainLayout>
  )
}

export default BecomeAnAffiliate
