import { getHeaders, functionUrls } from 'core/api'

interface TransformedData {
  name: string
  value: string
}

export const createHubspotTicket = async (ticketData: TransformedData[]) => {
  const functionUrl: string = functionUrls.createHubspotTicket

  try {
    const res = await fetch(functionUrl, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(ticketData),
    })

    console.log('createHubspotTicket Response status:', res.status)
    const responseBody = await res.text()

    if (res.status === 200) {
      const data = JSON.parse(responseBody)
      return data
    } else {
      console.log('Error response body:', responseBody)
      throw new Error(`Request failed with status ${res.status}`)
    }
  } catch (error) {
    console.error('Error in createHubspotTicket:', error)
    throw error
  }
}
