import React, { useState } from 'react'
import MainLayout from 'layouts/MainLayout'
import { Tabs, Tab, Divider } from '@mui/material'
import ContactsDash from './Contacts/ContactsDash'
import LeadsDash from './Leads/LeadsDash'
import { PageTitle } from 'components/PageTitle'

const HubspotDash = () => {
  const [tabIndex, setTabIndex] = useState(0)

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue)
  }

  return (
    <MainLayout>
      <PageTitle
        title='Hubspot Integration'
        subtitle='Use this interface to manage contacts and leads in hubspot.'
        bulletPoints={[
          'View and manage contacts and leads in hubspot.',
          'Create new contacts and leads.',
          'Update existing contacts and leads.',
        ]}
      />

      <Tabs value={tabIndex} onChange={handleTabChange} centered>
        <Tab label='Contacts' />
        <Tab label='Leads' />
      </Tabs>

      <Divider sx={{ marginBottom: 2 }} />

      {tabIndex === 0 && <ContactsDash />}
      {tabIndex === 1 && <LeadsDash />}
    </MainLayout>
  )
}

export default HubspotDash
