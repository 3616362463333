import React, { createContext, useState, useContext, useCallback, ReactNode } from 'react'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'

interface SnackbarContextType {
  showSnackbar: (
    message: string,
    severity?: 'error' | 'warning' | 'info' | 'success',
    size?: 'small' | 'medium' | 'large',
    position?: {
      vertical: 'top' | 'bottom'
      horizontal: 'left' | 'right' | 'center'
    },
  ) => void
}

const SnackbarContext = createContext<SnackbarContextType | null>(null)

export const useSnackbar = (): SnackbarContextType => {
  const context = useContext(SnackbarContext)
  if (!context) {
    throw new Error('useSnackbar must be used within a SnackbarProvider')
  }
  return context
}

interface SnackbarProviderProps {
  children: ReactNode
}

interface SnackbarState {
  open: boolean
  message: string
  severity: 'error' | 'warning' | 'info' | 'success'
  size: 'small' | 'medium' | 'large'
  position: {
    vertical: 'top' | 'bottom'
    horizontal: 'left' | 'right' | 'center'
  }
}

export const SnackbarProvider: React.FC<SnackbarProviderProps> = ({ children }) => {
  const [snackbar, setSnackbar] = useState<SnackbarState>({
    open: false,
    message: '',
    severity: 'info',
    size: 'medium',
    position: { vertical: 'top', horizontal: 'right' },
  })

  const showSnackbar = useCallback(
    (
      message: string,
      severity: 'error' | 'warning' | 'info' | 'success' = 'info',
      size: 'small' | 'medium' | 'large' = 'medium',
      position: {
        vertical: 'top' | 'bottom'
        horizontal: 'left' | 'right' | 'center'
      } = { vertical: 'top', horizontal: 'right' },
    ) => {
      setSnackbar({ open: true, message, severity, size, position })
    },
    [],
  )

  const sizeStylesSnackbar = {
    small: { width: '100%' },
    medium: { width: '100%', maxWidth: '300px' },
    large: { width: '100%', maxWidth: '600px' },
  }

  const sizeStylesAlert = {
    small: { width: '100%' },
    medium: { width: '100%', fontSize: '1rem', padding: '12px' },
    large: { width: '100%', fontSize: '1.25rem', padding: '16px' },
  }

  const handleClose = useCallback(() => {
    setSnackbar(prev => ({ ...prev, open: false }))
  }, [])

  return (
    <SnackbarContext.Provider value={{ showSnackbar }}>
      {children}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={snackbar.position}
        sx={sizeStylesSnackbar[snackbar.size]}
      >
        <Alert onClose={handleClose} severity={snackbar.severity} sx={sizeStylesAlert[snackbar.size]}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  )
}
