import React, { useState, useEffect } from 'react'
import { Button, Box, Grid, Dialog } from '@mui/material'
import { generateW9Url, submitW9 } from 'core/api/application/client'

const W9Form = () => {
  const [iframeUrl, setIframeUrl] = useState('')
  const [loading, setLoading] = useState(false)
  const [formCompleted, setFormCompleted] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false) // State to manage dialog open/close

  const handleGenerateW9Url = async (event: { preventDefault: () => void }) => {
    event.preventDefault()
    setLoading(true)

    generateW9Url().then(res => {
      setIframeUrl(res.message)
      setFormCompleted(true)
      setDialogOpen(true)
      setLoading(false)
    })
  }

  useEffect(() => {
    const handleMessage = (event: { data: { type: string; payload: PandaDocument } }) => {
      const { type, payload } = event.data || {}
      if (type === 'embed.form.completed') {
        submitW9(payload.document.id).then(() => {})
        setFormCompleted(false)
        setDialogOpen(false)
      }
    }

    window.addEventListener('message', handleMessage)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [])

  return (
    <div>
      <Button onClick={handleGenerateW9Url} variant='contained' color='primary' sx={{ marginTop: 2 }}>
        Complete W9 Form
      </Button>

      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)} // Close dialog
        fullWidth
        maxWidth='md' // Adjust size as needed
      >
        {formCompleted && !loading && (
          <Box sx={{ mb: 10 }}>
            <Grid
              container
              justifyContent='center'
              alignItems='center'
              sx={{ width: '100%', height: 'calc(100vh - 64px)' }}
            >
              <iframe
                src={iframeUrl}
                style={{ width: '100%', height: '100%', display: loading ? 'none' : 'block' }}
                frameBorder='0'
                allowFullScreen
                scrolling='no'
                title='Embedded Content'
                onLoad={() => setLoading(false)}
              />
            </Grid>
          </Box>
        )}
      </Dialog>
    </div>
  )
}

export default W9Form
