import React, { useState } from 'react'
import {
  Button,
  TextField,
  Link,
  Grid,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  CircularProgress,
  Tooltip,
} from '@mui/material'
import { useAuth } from 'contexts/AuthContext'
import AuthLayout from 'layouts/AuthLayout'
import { authTextFieldStyle } from 'theme'
import { useNavigate } from 'react-router-dom'
import { verifyPasswordRequirements, verifyProperEmail } from 'core/utils'

interface DialogContent {
  title: string
  body: string | JSX.Element
  button: string
}

const SignUp = () => {
  const navigate = useNavigate()
  const { signUp } = useAuth()
  const [openDialog, setOpenDialog] = useState(false)
  const [dialogContent, setDialogContent] = useState<DialogContent>({ title: '', body: '', button: '' })
  const [dialogRedirect, setDialogRedirect] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  Tooltip

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    {
      /* 1. Grab Form Data */
    }
    event.preventDefault()
    const formData = new FormData(event.currentTarget)
    const email = formData.get('email') as string
    const firstName = formData.get('firstName') as string
    const lastName = formData.get('lastName') as string
    const password = formData.get('password') as string
    const passwordConfirmation = formData.get('passwordConfirmation') as string

    const errors = []

    if (!verifyProperEmail(email)) {
      errors.push('Please make sure you enter a valid email address.')
    }

    if (firstName.length < 1) {
      errors.push('First name is required.')
    }

    if (lastName.length < 1) {
      errors.push('Last name is required.')
    }

    if (password !== passwordConfirmation) {
      errors.push('Please make sure you confirm your password correctly.')
    }

    if (!verifyPasswordRequirements(password)) {
      errors.push(
        'Your password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, and one number.',
      )
    }

    if (errors.length > 0) {
      const errorContent = (
        <ul>
          {errors.map((error, index) => (
            <li key={index}>{error}</li>
          ))}
        </ul>
      )

      setDialogContent({
        title: 'Signup Error',
        body: errorContent, // render jsx error list
        button: 'Ok',
      })

      setDialogRedirect('/signup')
      setOpenDialog(true)
      return
    }

    {
      /* 3. Create Credentials Obj and pass to Signup */
    }
    const credentials = {
      email: email,
      firstName: firstName,
      lastName: lastName,
      password: password,
    }

    setIsLoading(true)
    const response = await signUp(credentials)
    setIsLoading(false)

    {
      /* 4. Handle Signup Response - 200 Success */
    }
    if (response) {
      setDialogContent({
        title: 'Verify your email.',
        body: 'A verification link has been sent to your email, please verify before logging in.',
        button: 'Ok',
      })
      setDialogRedirect('/login')
      setOpenDialog(true)
      return

      {
        /* 4. Handle Signup Response - 409 Conflict - Email Already Exists */
      }
    } else if (!response) {
      setDialogContent({
        title: 'An account already exists with this email.',
        body: 'Please try again with a different email.',
        button: 'Ok',
      })
      setDialogRedirect('/signup')
      setOpenDialog(true)
      return

      {
        /* 4. Handle Signup Response - Another Unknown Issue Occured in the Workflow */
      }
    } else {
      setDialogContent({
        title: 'There was an issue signing you up.',
        body: 'Please try again later or contact an admin.',
        button: 'Ok',
      })
      setDialogRedirect('/signup')
      setOpenDialog(true)
      return
    }
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
    if (dialogRedirect) {
      navigate(dialogRedirect)
    }
  }

  const SignupDialog = (): JSX.Element => {
    return (
      <>
        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>{dialogContent.title}</DialogTitle>
          <DialogContent>
            <Typography>{dialogContent.body}</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color='primary'>
              {dialogContent.button}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }

  const LoadingDialog = () => (
    <Dialog open={isLoading}>
      <DialogTitle>Signing you up..</DialogTitle>
      <Box sx={{ margin: 2, textAlign: 'center' }}>
        <CircularProgress />
      </Box>
    </Dialog>
  )

  return (
    <AuthLayout>
      {/* Dialogs */}
      <SignupDialog />
      <LoadingDialog />
      {/* Sign up form  */}

      <Box component='form' onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <Typography
          component='p'
          sx={{
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          An Admin will need to verify your account before you can login. Please contact admin@optictruckworks.com if
          you have any questions.
        </Typography>
        <TextField
          margin='normal'
          required
          fullWidth
          id='email'
          label='Email Address'
          name='email'
          autoComplete='email'
          autoFocus
          sx={authTextFieldStyle}
        />
        <TextField
          margin='normal'
          required
          fullWidth
          name='firstName'
          label='First Name'
          type='firstName'
          id='firstName'
          autoComplete='first-name'
          sx={authTextFieldStyle}
        />

        <TextField
          margin='normal'
          required
          fullWidth
          name='lastName'
          label='Last Name'
          type='lastName'
          id='lastName'
          autoComplete='last-name'
          sx={authTextFieldStyle}
        />
        <Tooltip
          title='Password must be at least 8 characters long and include uppercase, lowercase, and a number.'
          placement='right'
          enterDelay={300}
          leaveDelay={200}
        >
          <TextField
            margin='normal'
            required
            fullWidth
            name='password'
            label='Password'
            type='password'
            id='password'
            autoComplete='current-password'
            sx={authTextFieldStyle}
          />
        </Tooltip>
        <TextField
          margin='normal'
          required
          fullWidth
          name='passwordConfirmation'
          label='Confirm Password'
          type='password'
          id='passwordConfirmation'
          autoComplete='new-password'
          sx={authTextFieldStyle}
        />
        <Button type='submit' fullWidth variant='contained' sx={{ mt: 3, mb: 2 }}>
          Sign Up
        </Button>
        <Grid container justifyContent='flex-end'>
          <Grid item>
            <Link href='/login' variant='body2'>
              Already have an account? Sign in
            </Link>
          </Grid>
        </Grid>
      </Box>
    </AuthLayout>
  )
}

export default SignUp
