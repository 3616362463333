import { doc, getDoc } from '@firebase/firestore'
import { getDownloadURL, getStorage, listAll, ref } from '@firebase/storage'
import { getUserProfile } from 'core/api/users'
import { db } from 'core/config/firebase'

import { unixTimestampToISO } from 'core/utils/unixTimestampToISO'


export const getPhotoSubmissionObject = async (props: PhotoSubmissionForeignKey): Promise<PhotoSubmissionObject> => {
  const { userId, photoId } = props
  const storage = getStorage()

  const photoSubmissionRef = doc(db, 'users', userId, 'vehicles', photoId)
  const photoSubmissionDoc = await getDoc(photoSubmissionRef)

  if (photoSubmissionDoc.exists()) {
    const data = photoSubmissionDoc.data()
    const imageRefs = await listAll(ref(storage, `users/${userId}/photos/${photoId}`))
    const imageUrls: FirebasePhotoObject[] = await Promise.all(
      imageRefs.items.map(async (imageRef) => {
        const blobUrl = await getDownloadURL(imageRef);
        const fullPath = imageRef.fullPath;
        return { fullPath, blobUrl };
      })
    );

    const user: UserInfoType | undefined = await getUserProfile(userId)
    const dateForSubmission = unixTimestampToISO(data.LastUploadedAt.seconds, data.LastUploadedAt.nanoseconds)

    return {
      userId: userId,
      photoId: photoId,
      dateUploaded: dateForSubmission,
      vehicleId: data.id,
      photos: imageUrls,
      user: user ? user : undefined,
    }
  } else {
    throw new Error('Photo submission not found')
  }
}
