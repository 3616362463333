import ClearIcon from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'
import { CircularProgress, IconButton, InputAdornment, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'

export type genericDataObject = { [key: string]: string | number | boolean }

interface QuickFilterProps {
  data: genericDataObject[]
  onFilterResults: (results: genericDataObject[]) => void
}

const QuickFilter: React.FC<QuickFilterProps> = ({ data, onFilterResults }) => {
  const [filterValue, setFilterValue] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!data) return

    setIsLoading(true)
    const filteredData: genericDataObject[] = data.filter(dataObject => {
      const query = filterValue.toLowerCase()
      const matchesSearchQuery = Object.values(dataObject).some(value => {
        if (!value) return false

        return value.toString().toLowerCase().includes(query)
      })

      return matchesSearchQuery
    })

    onFilterResults(filteredData)
    setIsLoading(false)
  }, [filterValue])

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterValue(e.target.value)
  }

  const clearFilter = () => {
    setFilterValue('')
  }

  return (
    <TextField
      fullWidth
      variant='outlined'
      placeholder={`Search...`}
      value={filterValue}
      onChange={handleFilterChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position='end'>
            {isLoading ? (
              <CircularProgress size={24} />
            ) : (
              filterValue && (
                <IconButton aria-label='clear filter' onClick={clearFilter} edge='end'>
                  <ClearIcon />
                </IconButton>
              )
            )}
          </InputAdornment>
        ),
      }}
    />
  )
}

export default QuickFilter
