import React, { useState, useEffect } from 'react'
import { Dialog, DialogContent } from '@mui/material'
import { adminFunctions } from 'core/api/application/admin'

import { useMediaQuery, useTheme } from '@mui/material'

interface ViewW9FormProps {
  isDialogOpen: boolean
  handleCloseDialog: () => void
  documentUUID: string
}

const ViewW9Form = ({ isDialogOpen, handleCloseDialog, documentUUID }: ViewW9FormProps) => {
  const [documentUrl, setDocumentUrl] = useState('')


  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    if (documentUUID) {
      const getDocumentUrl = async () => {
        const url = await adminFunctions.getViewableDocument(documentUUID)
        setDocumentUrl(url)
      }

      getDocumentUrl()
    }
  }, [documentUUID])

  return (
    <Dialog
      open={isDialogOpen}
      onClose={handleCloseDialog}
      fullWidth
      maxWidth={isMobile ? 'sm' : 'lg'} // Adjust the maxWidth for mobile
      // PaperProps={{
      //     style: {
      //         height: isMobile ? 'auto' : '100%', // Use auto height for mobile for better flexibility
      //         maxHeight: isMobile ? '90vh' : 'calc(100vh - 64px)', // Adjust the maxHeight for mobile
      //         overflowY: 'auto', // Use auto instead of hidden to allow scrolling if needed
      //     },
      // }}
    >
      <DialogContent>
        <iframe
          src={documentUrl}
          style={{
            // height: isMobile ? '50vh' : 'calc(100vh - 64px)', // Adjust iframe height for mobile
            height: 'calc(100vh - 64px)',
            width: '100%',
            border: 'none',
          }}
          allowFullScreen
        />
      </DialogContent>
    </Dialog>
  )
}

export default ViewW9Form
