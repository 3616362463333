import React, { useEffect, useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  Tab,
  Tabs,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  CircularProgress,
} from '@mui/material'
import { getClientApplications, getViewableDocument, getDocumentForCorrections } from 'core/api/application/client'
import { CorrectionsDialog } from './components/CorrectionsDialog'
import { formatDateToLocalTime } from '../../../../core/utils/formatDateToLocalTime'

const ApplicationTable = (): JSX.Element => {
  const [applications, setApplications] = useState<Application[]>([])
  const [documentUrl, setDocumentUrl] = useState<string | null>(null)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [errorDialogOpen, setErrorDialogOpen] = useState(false)
  const [loadingDialogOpen, setLoadingDialogOpen] = useState(false)
  const [currentTab, setCurrentTab] = useState(0)
  const [filteredApps, setFilteredApps] = useState<Application[]>([])

  const [correctionsDialogOpen, setCorrectionsDialogOpen] = useState(false)
  const [correctionsUrl, setCorrectionsUrl] = useState<string | null>(null)
  const [appForCorrections, setAppForCorrections] = useState<Application | null>(null)

  useEffect(() => {
    getClientApplications().then(res => {
      setApplications(res)
    })
  }, [currentTab])

  useEffect(() => {
    if (!applications) return
    const formattedApplications = applications.map(app => ({
      ...app,
      time_created: formatDateToLocalTime(app.time_created)
    }));
    const filtered = formattedApplications.filter(app => {
      switch (currentTab) {
        case 0: // Pending tab includes 'Ready', 'Pending', and 'Viewed' applications
          return app.status === 'Ready' || app.status === 'Pending' || app.status === 'Viewed'
        case 1:
          return app.status === 'Corrections Needed'
        case 2: // Approved tab
          return app.status === 'Approved'
        case 3: // Denied tab
          return app.status === 'Denied'
        default:
          return false
      }
    })
    setFilteredApps(filtered)
  }, [currentTab, applications])

  const handleViewDocument = async (document_uuid: string) => {
    setLoadingDialogOpen(true)
    try {
      const url = await getViewableDocument(document_uuid)
      setDocumentUrl(url)
      setLoadingDialogOpen(false)
      setIsDialogOpen(true) // Open the dialog
    } catch (error) {
      setLoadingDialogOpen(false)
      setErrorDialogOpen(true)
      console.error('Error fetching document:', error)
    }
  }

  const handleMakeCorrections = async (application: Application) => {
    setAppForCorrections(application)
    setCorrectionsDialogOpen(true)
    try {
      const url = await getDocumentForCorrections(application.application_id)
      setCorrectionsUrl(url)
      setLoadingDialogOpen(false)
      setCorrectionsDialogOpen(true)
    } catch (error) {
      setLoadingDialogOpen(false)
      setErrorDialogOpen(true)
      console.error('Error fetching document:', error)
    }
  }

  const handleCloseDialog = () => {
    setIsDialogOpen(false)
    setDocumentUrl(null) // Reset the URL
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue)
  }

  const ErrorDialog = () => (
    <Dialog open={errorDialogOpen} onClose={handleCloseErrorDialog}>
      <DialogTitle>Error</DialogTitle>
      <DialogContent>
        <Typography>
          There was an error loading the document. Please try again or submit a support request.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button href='/support-request'>Submit Support Request</Button>
        <Button onClick={handleCloseErrorDialog}>Close</Button>
      </DialogActions>
    </Dialog>
  )

  const handleCloseErrorDialog = () => {
    setErrorDialogOpen(false)
  }

  const handleCloseLoadingDialog = () => {
    setErrorDialogOpen(false)
  }

  const LoadingDialog = () => (
    <Dialog open={loadingDialogOpen} onClose={handleCloseLoadingDialog}>
      <DialogTitle>Loading Document..</DialogTitle>
      <DialogContent>
        <CircularProgress />
      </DialogContent>
    </Dialog>
  )

  return (
    <>
      <ErrorDialog />
      <LoadingDialog />

      {appForCorrections && correctionsUrl && (
        <CorrectionsDialog
          open={correctionsDialogOpen}
          onClose={() => setCorrectionsDialogOpen(false)}
          application={appForCorrections}
          documentUrl={correctionsUrl}
        />
      )}

      <Tabs value={currentTab} onChange={handleTabChange} centered>
        <Tab label='Pending' />
        <Tab label='Corrections Needed' />
        <Tab label='Approved' />
        <Tab label='Denied' />
      </Tabs>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>Bank Name</TableCell>

              <TableCell>Date Created</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredApps.map((app, index) => (
              <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>{app.bank_name}</TableCell>
                <TableCell>{app.time_created}</TableCell>
                <TableCell>{app.status === 'Viewed' ? 'Viewed By Bank' : app.status}</TableCell>

                {currentTab !== 1 && (
                  <TableCell>
                    <Button
                      onClick={() => handleViewDocument(app.document_uuid)}
                      variant='contained'
                      color='primary'
                      sx={{ mr: 1 }}
                    >
                      View
                    </Button>
                  </TableCell>
                )}

                {currentTab === 1 && (
                  <TableCell>
                    <Button
                      onClick={() => handleMakeCorrections(app)}
                      variant='contained'
                      color='primary'
                      sx={{ mr: 1 }}
                    >
                      Make Corrections
                    </Button>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        PaperProps={{
          style: {
            height: '100%',
            maxHeight: 'calc(100vh - 64px)', // Adjust as needed
            overflowY: 'hidden', // Prevents double scrolling
          },
        }}
        fullWidth
        maxWidth='lg'
      >
        {documentUrl && (
          <iframe
            src={documentUrl}
            style={{
              height: 'calc(100vh - 64px)', // Adjust as needed
              width: '100%',
              border: 'none',
            }}
            allowFullScreen
          />
        )}
      </Dialog>
    </>
  )
}

export default ApplicationTable
