import React, { useState } from 'react'
import { Dialog, DialogContent, DialogActions, DialogTitle, Button } from '@mui/material'
import CreateTicketForm from './CreateTicketForm'

interface CreateTicketProps {
  open: boolean
  handleClose: () => void
}

const CreateTicket: React.FC<CreateTicketProps> = ({ open, handleClose }) => {
  const [errorCreatingTicket, setErrorCreatingTicket] = useState<string | null>(null)

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Create New HubSpot Lead</DialogTitle>
      <DialogContent>
        <CreateTicketForm
          handleClose={handleClose}
          setErrorCreatingTicket={setErrorCreatingTicket}
          errorCreatingTicket={errorCreatingTicket}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  )
}

export default CreateTicket
