import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from 'contexts/AuthContext'
import { IsLoading, NotFound } from 'components'
import { Navigate } from 'react-router-dom'

interface SuperUserRouteProps {
  children: React.ReactNode
}

const useSuperUserRoute = () => {
  const { isAuthenticated, isSuperuser, isLoading } = useAuth()
  const navigate = useNavigate()
  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login')
    } else if (!isSuperuser()) {
      ;<Navigate to='/not-found' />
    }
  }, [isAuthenticated, navigate, isSuperuser])

  return { isAuthenticated, isSuperuser: isSuperuser(), isLoading }
}

const SuperUserRoute = ({ children }: SuperUserRouteProps) => {
  const { isAuthenticated, isSuperuser, isLoading } = useSuperUserRoute()

  if (isLoading) {
    return <IsLoading />
  }

  if (!isAuthenticated || !isSuperuser) {
    return <NotFound />
  }

  return <>{children}</>
}

export default SuperUserRoute
