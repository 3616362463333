import {
  Box,
  CircularProgress,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material'
import { PageTitle } from 'components/PageTitle'
import useVinDecoder from 'hooks/useVinDecoder'
import { MainLayout } from 'layouts'
import React, { useState } from 'react'

const VINDecoder: React.FC = () => {
  const [vin, setVin] = useState('')

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVin(event.target.value)
  }

  const { data: vinData, isLoading, error } = useVinDecoder(vin)

  return (
    <MainLayout>
      <Container maxWidth='md'>
        <Box sx={{ my: 4 }}>
          <PageTitle
            title='VIN Decoder'
            subtitle='Decode a Vehicle Identification Number (VIN) to get detailed information about the vehicle.'
            bulletPoints={[
              'Enter the 17-character VIN in the input field below.',
              'The VIN Decoder uses the NHTSA API to fetch vehicle information.',
            ]}
          />
          <TextField
            label='Enter VIN'
            variant='outlined'
            value={vin}
            onChange={handleInputChange}
            fullWidth
            sx={{ mb: 2 }}
          />
          {isLoading && <CircularProgress sx={{ mt: 2 }} />}
          {error && (
            <Typography color='error' sx={{ mt: 2 }}>
              {error.message.toString()}
            </Typography>
          )}
          {vinData && vinData.length > 0 && (
            <TableContainer component={Paper} sx={{ mt: 4 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Variable</TableCell>
                    <TableCell>Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {vinData && vinData
                    .filter(
                      item =>
                        item.Value !== null &&
                        item.Value !== 'Not Applicable' &&
                        item.Value !== '' &&
                        item.Variable !== 'Error Code' &&
                        item.Variable !== 'Possible Values' &&
                        item.Variable !== 'Error Text',
                    )
                    .map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{item.Variable}</TableCell>
                        <TableCell>{item.Value}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      </Container>
    </MainLayout>
  )
}

export default VINDecoder
