import { doc, getDoc, setDoc } from '@firebase/firestore'
import { db } from 'core/config/firebase'
import { getDownloadURL, getStorage, listAll, ref, uploadBytes } from 'firebase/storage'

export const addToWebsiteInventory = async (inventoryId: string) => {
  const storage = getStorage()

  // Reference to the storage location in master_inventory
  const storageRef = ref(storage, `master_inventory/${inventoryId}`)

  // Get all objects in the master_inventory path
  const storageObjects = await listAll(storageRef)

  // Array to hold download URLs of the photos
  const photos: string[] = []

  // Get the document from master_inventory
  const masterDocRef = doc(db, 'master_inventory', inventoryId)
  const masterDocSnap = await getDoc(masterDocRef)

  if (masterDocSnap.exists()) {
    // Copy the document to website_inventory
    const websiteDocRef = doc(db, 'website_inventory', inventoryId)
    await setDoc(websiteDocRef, masterDocSnap.data())
  }

  // Copy each file to the new location
  for (const item of storageObjects.items) {
    const downloadURL = await getDownloadURL(item)
    const response = await fetch(downloadURL, { mode: 'no-cors' })
    const blob = await response.blob()

    const newStorageRef = ref(storage, `website_inventory/${inventoryId}/${item.name}`)
    await uploadBytes(newStorageRef, blob)

    photos.push(downloadURL)
  }

  // Log the photos array to verify the URLs
  await setDoc(doc(db, 'master_inventory', inventoryId), { show_on_website: true }, { merge: true })
}
