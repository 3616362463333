import { ref, getDownloadURL, uploadBytes, getStorage } from 'firebase/storage'

export const copyStorageFileToNewLocation = async (originalPath: string, newLocation: string) => {
  const storage = getStorage()

  const storageRef = ref(storage, originalPath)
  try {
    const blob = await (await fetch(await getDownloadURL(storageRef))).blob()
    const newRef = ref(storage, newLocation)
    await uploadBytes(newRef, blob)
    console.log(`Image copied successfully to ${newLocation}`)
    return getDownloadURL(newRef)
  } catch (error) {
    console.error('Failed to copy image:', error)
  }
}
