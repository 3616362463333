import { Box, Grid, List } from '@mui/material'
import { useFirestoreQuery } from '@react-query-firebase/firestore'
import QuickFilter, { genericDataObject } from 'components/QuickFilter'
import { db } from 'core/config/firebase'
import { ContactSubmission, RFISubmission, SellSubmission, SubmissionStatus } from 'core/types/form_submission'
import { collection, doc, updateDoc } from 'firebase/firestore'
import { useIsMobile } from 'hooks/screen/useIsMobile'
import React, { useCallback, useState } from 'react'
import ChangeStatusButton from './components/buttons/ChangeStatusButton'
import ContactSubmissionCard from './components/Cards/ContactSubmissionCard'
import RFISubmissionCard from './components/Cards/RFISubmissionCard'
import SellSubmissionCard from './components/Cards/SellSubmissionCard'
import SellSubmissionListItem from './components/ListItems/SellSubmissionListItem'

export type Submission = SellSubmission | RFISubmission | ContactSubmission

interface SubmissionBoardProps {
  collectionName: string
  onComplete: (submission: Submission) => void
  statusFilter: SubmissionStatus
  view: 'list' | 'card'
}

const SubmissionBoard: React.FC<SubmissionBoardProps> = ({ collectionName, onComplete, statusFilter, view }) => {
  const [filteredSubmissions, setFilteredSubmissions] = useState<Submission[]>([])
  const isMobile = useIsMobile()

  const { data: submissions, isLoading } = useFirestoreQuery(
    ['submissions', collectionName],
    collection(db, collectionName),
    {
      subscribe: true,
    },
    {
      select: snapshot => {
        if (!snapshot || !snapshot.docs) return []
        return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })) as Submission[]
      },
      onError: error => {
        console.error('Error fetching submissions:', error)
      },
      onSuccess: submissions => {
        setFilteredSubmissions(submissions)
      },
    },
  )

  const getSubmissionCard = useCallback(
    (submission: Submission) => {
      const handleClick = () => console.log(`Clicked submission: ${submission.id}`)
      switch (collectionName) {
        case 'form_sell':
          return (
            <SellSubmissionCard
              submission={submission as SellSubmission}
              onClick={handleClick}
              onSubmit={() => changeStatus(submission.id, 'Completed')}
            />
          )
        case 'form_rfi':
          return (
            <RFISubmissionCard
              submission={submission as RFISubmission}
              onClick={handleClick}
              onSubmit={() => changeStatus(submission.id, 'Completed')}
            />
          )
        case 'form_contact':
          return (
            <ContactSubmissionCard
              submission={submission as ContactSubmission}
              onClick={handleClick}
              onSubmit={() => changeStatus(submission.id, 'Completed')}
            />
          )
        default:
          return null
      }
    },
    [collectionName],
  )

  const getSubmissionListItem = useCallback(
    (submission: Submission, button: JSX.Element) => {
      const handleClick = () => console.log(`Clicked submission: ${submission.id}`)
      switch (collectionName) {
        case 'form_sell':
          return (
            <SellSubmissionListItem
              submission={submission as SellSubmission}
              actionButton={button}
              onClick={handleClick}
              onSubmit={() => changeStatus(submission.id, 'Completed')}
            />
          )
        case 'form_rfi':
          return null
        case 'form_contact':
          return null
        default:
          return null
      }
    },
    [collectionName],
  )

  const changeStatus = async (submissionId: string, status: string) => {
    try {
      const submissionRef = doc(db, collectionName, submissionId)
      await updateDoc(submissionRef, {
        status: status,
      })
    } catch (error) {
      console.error('Error updating submission status:', error)
    }
    if (status === 'Completed') {
      onComplete(submissions?.find(sub => sub.id === submissionId) as Submission)
    }
  }

  const handleFilterResults = (results: genericDataObject[]) => {
    setFilteredSubmissions(results as unknown as Submission[])
  }

  return (
    <Box>
      <Box
        display='flex'
        flexDirection={isMobile ? 'column' : 'row'}
        justifyContent='flex-start'
        alignItems='center'
      >
        <Box ml={2} px={2} mt={0} width={isMobile ? '100%' : 300}>
          <QuickFilter
            data={(submissions as unknown as genericDataObject[]) ?? []}
            onFilterResults={handleFilterResults}
          />
        </Box>
      </Box>

      {view === 'card' && (
        <Box sx={{ display: 'flex', flexDirection: 'column', p: 2 }}>
          <Grid container spacing={2}>
            {!isLoading &&
              filteredSubmissions
                .filter(submission => submission.status === statusFilter)
                .map(submission => (
                  <Grid key={submission.id} item xs={12} sm={6} md={4} lg={3}>
                    <Box display='flex' flexDirection='column'>
                      {getSubmissionCard(submission)}
                      <ChangeStatusButton
                        status={submission.status}
                        onStatusChange={newStatus => changeStatus(submission.id, newStatus)}
                      />
                    </Box>
                  </Grid>
                ))}
          </Grid>
        </Box>
      )}
      {view === 'list' && (
        <Box sx={{ display: 'flex', flexDirection: 'column', p: 2 }}>
          <List>
            {!isLoading &&
              filteredSubmissions
                .filter(submission => submission.status === statusFilter)
                .map(submission =>
                  getSubmissionListItem(
                    submission,
                    <ChangeStatusButton
                      status={submission.status}
                      onStatusChange={newStatus => changeStatus(submission.id, newStatus)}
                    />,
                  ),
                )}
          </List>
        </Box>
      )}
    </Box>
  )
}

export default SubmissionBoard
