import CloseIcon from '@mui/icons-material/Close'
import SendIcon from '@mui/icons-material/Send'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import { Button, IconButton, ImageList, ImageListItem, ImageListItemBar } from "@mui/material"
import React, { ChangeEvent, useState } from "react"


interface UploadPhotosTabProps {
    onSubmitAddedPhotos: (stagedPhotos: { file: File; previewBlobUrl: string }[]) => void,
}

const UploadPhotosTab: React.FC<UploadPhotosTabProps> = ({ onSubmitAddedPhotos }) => {
      const [stagedPhotos, setStagedPhotos] = useState<{ file: File; previewBlobUrl: string }[]>([])

      const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
          return
        }
    
        const filesArray = Array.from(e.target.files)
        const filesWithPreview = filesArray.map(file => ({
          file,
          previewBlobUrl: URL.createObjectURL(file),
        }))
    
        console.log('filesWithPreview', filesWithPreview)
        setStagedPhotos(prev => [...prev, ...filesWithPreview])
      }

      const handleRemoveStagedPhoto = (index: number) => {
        setStagedPhotos(prev => prev.filter((_, i) => i !== index))
      }
      const handleSubmitAddedPhotos = () => {
        onSubmitAddedPhotos(stagedPhotos)
        setStagedPhotos([])
      }

    return (
      <>
        <Button component='label' variant='outlined' startIcon={<UploadFileIcon />} sx={{ margin: '1rem' }}>
          Add Photos
          <input type='file' accept='.jpg,jpeg,.png' hidden onChange={handleFileUpload} />
        </Button>
        {stagedPhotos.length > 0 && (
          <Button
            component='label'
            variant='outlined'
            onClick={handleSubmitAddedPhotos}
            startIcon={<SendIcon />}
            sx={{ margin: '1rem' }}
          >
            Upload Photos
          </Button>
        )}

        <ImageList sx={{ width: 500, height: 450 }} cols={3} rowHeight={164}>
          {stagedPhotos && stagedPhotos.length > 0 && (
            <>
              {stagedPhotos.map(({ file, previewBlobUrl }, index) => (
                <ImageListItem key={index}>
                  <img
                    src={previewBlobUrl}
                    alt={file.name}
                    loading='lazy'
                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                  />
                  <ImageListItemBar
                    position='top'
                    actionIcon={
                      <IconButton
                        sx={{ color: 'red', background: 'black', padding: '2px' }}
                        aria-label={`close ${file.name}`}
                        onClick={() => handleRemoveStagedPhoto(index)}
                      >
                        <CloseIcon />
                      </IconButton>
                    }
                    actionPosition='right'
                    sx={{ background: 'transparent', padding: '4px' }}
                  />
                </ImageListItem>
              ))}
            </>
          )}
        </ImageList>
      </>
    )
  }

export default UploadPhotosTab;