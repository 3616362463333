import {
  Home as HomeIcon,
  Settings as SettingsIcon,
  Build as BuildIcon,
  AccountTree as AccountTreeIcon,
  RecentActors as RecentActorsIcon,
  Calculate as CalculateIcon,
  LocalShipping as LocalShippingIcon,
  Loyalty as LoyaltyIcon,
  Description as DescriptionIcon,
  Paid as PaidIcon,
  Group as GroupIcon,
  PostAdd as PostAddIcon,
  Inventory2 as StockIcon,
  AssignmentInd as SubmissionIcon,
  SvgIconComponent,
} from '@mui/icons-material'



export type NavItem =
  | { title: string; path: string; icon?: SvgIconComponent; submenu?: never }
  | {
      title: string
      icon?: SvgIconComponent
      submenu: { title: string; path: string; icon?: SvgIconComponent }[]
      path?: never
    }

export const pages: NavItem[] = [
  { title: 'Home', path: '/', icon: HomeIcon },
  {
    title: 'SubMenu Example',
    icon: SettingsIcon,
    submenu: [
      { title: 'SubPage 1', path: '/subpage1' },
      { title: 'SubPage 2', path: '/subpage2' },
    ],
  },
]

export const bankPages: NavItem[] = [{ title: 'Bank Home', path: '/bankAgent', icon: HomeIcon }]

export const clientPages: NavItem[] = [
  { title: 'Home', path: '/client', icon: HomeIcon },
  {
    title: 'Apply for Financing',
    path: '/client/application',
    icon: PostAddIcon,
  },
  {
    title: 'Truck Debt Overhaul',
    path: '/client/debt-overhaul',
    icon: PaidIcon,
  },
]

export const dealerPages: NavItem[] = [{ title: 'Home', path: '/dealer', icon: HomeIcon }]

export const superuserPages: NavItem[] = [
  { title: 'Admin Home', path: '/admin', icon: HomeIcon },
  {
    title: 'Tools',
    icon: BuildIcon,
    submenu: [
      { title: 'Hubspot Integration', path: '/admin/hubspot-integration', icon: RecentActorsIcon },
      {
        title: 'Depreciation Calculator',
        path: '/admin/depreciation-calculator',
        icon: CalculateIcon,
      },
      {
        title: 'VIN Decoder',
        path: '/admin/vin-decoder',
        icon: LocalShippingIcon,
      },
    ],
  },
  {
    title: 'Management',
    icon: AccountTreeIcon,
    submenu: [
      {
        title: 'Optic Inventory Management',
        path: '/admin/optic-inventory-management',
        icon: StockIcon,
      },
      {
        title: 'Shield Inventory Management',
        path: '/admin/shield-inventory-management',
        icon: StockIcon,
      },
      {
        title: 'Submission Management',
        path: '/admin/submission-management',
        icon: SubmissionIcon,
      },
      {
        title: 'Affiliate Management',
        path: '/admin/affiliate-management',
        icon: LoyaltyIcon,
      },
      {
        title: 'Application Management',
        path: '/admin/application-management',
        icon: DescriptionIcon,
      },
      {
        title: 'Debt Management',
        path: '/admin/truck-debt-overhaul-management',
        icon: PaidIcon,
      },
      {
        title: 'User Management',
        path: '/admin/user-management',
        icon: GroupIcon,
      },
    ],
  },
]
