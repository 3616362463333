import React, { useState, useEffect } from 'react'
import { MainLayout } from 'layouts'
import { Typography, CircularProgress, Box, Button, Grid } from '@mui/material'
import { generateApplicationUrl, postFinishedApplication } from 'core/api/application/client'

const ApplicationNew = () => {
  const [iframeUrl, setIframeUrl] = useState('')
  const [formCompleted, setFormCompleted] = useState(false)
  const [loading, setLoading] = useState(false)
  // const [formData, setFormData] = useState({
  //     totalFinancing: '',
  //     creditScoreRange: '',
  //     businessRevenue: ''
  // });

  const [pandaPayload, setPandaPayload] = useState<PandaDocument | null>(null)

  // const handleChange = (event: any) => {
  //     const { name, value } = event.target;
  //     setFormData(prevState => ({ ...prevState, [name]: value }));
  // };

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault()
    setFormCompleted(true)
    setLoading(true)
    // Update this function call as needed to pass the form data

    generateApplicationUrl().then(res => {
      setIframeUrl(res.message)
      setLoading(false)
    })
  }

  useEffect(() => {
    const handleMessage = (event: { data: { type: string; payload: PandaDocument } }) => {
      const { type, payload } = event.data || {}
      if (type === 'embed.form.completed') {
        setPandaPayload(payload)
        setFormCompleted(false)
      }
    }

    window.addEventListener('message', handleMessage)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [])

  useEffect(() => {
    if (pandaPayload) {
      const pandadata = {
        document_uuid: pandaPayload.document.id,
        date_completed: pandaPayload.document.date_completed,
        template_uuid: pandaPayload.document.created_from_template.id,
      }

      postFinishedApplication(pandadata).then(res => {
        if (res.status === 200) {
          // TODO: success dialog
          console.log('Application Submitted Successfully!')
        } else {
          // TODO: error dialog
          console.log('Application Failed to submit!')
        }
      })
    }
  }, [pandaPayload])

  return (
    <MainLayout>
      {!formCompleted && (
        <Grid container justifyContent='center' alignItems='center'>
          <Grid>
            <Typography variant='h5' component='h2' gutterBottom>
              We have partnered with PandaDoc to provide you a secure and easy way to complete your financing
              process.
            </Typography>
          </Grid>

          <Grid container justifyContent='center' alignItems='center'>
            <Typography variant='h5' component='h2' gutterBottom>
              Click the button below to begin your application.
            </Typography>
          </Grid>

          <Grid container justifyContent='center' alignItems='center'>
            {/* <Typography variant="h5" component="h2" gutterBottom>
                        Please provide us some general information about your application.
                    </Typography> */}

            <form onSubmit={handleSubmit}>
              {/* <TextField
                            label="Total Dollar Amount of Financing Desired"
                            variant="outlined"
                            name="totalFinancing"
                            value={formData.totalFinancing}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Credit Score Range"
                            variant="outlined"
                            name="creditScoreRange"
                            value={formData.creditScoreRange}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Business Revenue"
                            variant="outlined"
                            name="businessRevenue"
                            value={formData.businessRevenue}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                        /> */}
              <Button type='submit' variant='contained' color='primary' sx={{ marginTop: 2 }}>
                Begin Application
              </Button>
            </form>
          </Grid>
        </Grid>
      )}

      {loading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'calc(100vh - 64px)' }}>
          <Typography sx={{ mr: 2 }}>Application Loading..</Typography>
          <CircularProgress />
        </Box>
      )}

      {formCompleted && !loading && (
        <Box sx={{ mb: 10 }}>
          <Grid
            container
            justifyContent='center'
            alignItems='center'
            sx={{ width: '100%', height: 'calc(100vh - 64px)' }}
          >
            <Typography component='p' gutterBottom marginTop={2} marginBottom={2}>
              We have partnered with PandaDoc to provide you with a secure and easy way to complete your application.
              Please contact support@optictruckworks.com <></>
              <br />
              if you have any questions.
            </Typography>
            <iframe
              src={iframeUrl}
              style={{
                width: '100%',
                height: '100%',
                display: loading ? 'none' : 'block',
              }}
              frameBorder='0'
              allowFullScreen
              scrolling='no'
              title='Embedded Content'
              onLoad={() => setLoading(false)}
            />
            <Typography component='p' gutterBottom marginTop={2} marginBottom={2}></Typography>
          </Grid>
        </Box>
      )}
    </MainLayout>
  )
}

export default ApplicationNew
