import React, { useEffect, useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material'
import { adminFunctions } from 'core/api/application/admin'

interface AssignAgentDialogProps {
  isOpen: boolean
  onAssign: (bankId: string, agentId: string) => void
  onClose: () => void
  setSelectedAgentId: (agentId: string) => void
  selectedBankId: string
  selectedBankName: string
  selectedAgentId: string
}

export const AssignAgentDialog: React.FC<AssignAgentDialogProps> = ({
  isOpen,
  onAssign,
  onClose,
  setSelectedAgentId,
  selectedBankId,
  selectedBankName,
  selectedAgentId,
}) => {
  // const [banks, setBanks] = useState<Bank[]>([]);
  const [agents, setAgents] = useState<BankAgent[]>([])

  // useEffect(() => {
  //     const fetchBanks = async () => {
  //         const res = await adminFunctions.getBanks();
  //         setBanks(res);
  //     };
  //     fetchBanks();
  // }, []);

  useEffect(() => {
    const fetchAgents = async () => {
      if (selectedBankId) {
        const res = await adminFunctions.getAgentsForBank(selectedBankId)
        setAgents(res)
      }
    }
    fetchAgents()
  }, [selectedBankId])

  const handleAgentChange = (event: { target: { value: string } }) => {
    setSelectedAgentId(event.target.value as string)
  }

  const handleAssignClick = () => {
    if (selectedBankId && selectedAgentId) {
      onAssign(selectedBankId, selectedAgentId)
    }
  }

  return (
    <Dialog fullWidth open={isOpen} onClose={onClose}>
      <DialogTitle>Assign Agent</DialogTitle>
      <DialogContent>
        <FormControl fullWidth sx={{ marginBottom: 4, marginTop: 2 }}>
          <TextField
            fullWidth
            label='Bank Name'
            margin='dense'
            value={selectedBankName}
            InputProps={{
              readOnly: true,
              style: { backgroundColor: '#f5f5f5' },
            }}
            variant='outlined'
          />
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id='agent-select-label'>Agent</InputLabel>
          <Select
            labelId='agent-select-label'
            id='agent-select'
            value={selectedAgentId}
            label='Agent'
            onChange={handleAgentChange}
          >
            {agents.map(agent => (
              <MenuItem key={agent.id} value={agent.id}>
                {agent.email}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleAssignClick}>Assign</Button>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

export default AssignAgentDialog
