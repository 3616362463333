
import { getJwtToken } from './getJwtToken'

export const getHeaders = (): HeadersInit => {
  const jwtToken = getJwtToken()
  return {
    'Content-Type': 'application/json',
    ...(jwtToken ? { Authorization: `Bearer ${jwtToken}` } : {}),
  }
}
