import React, { useState } from 'react'
import {
  Box,
  List,
  ListItemButton,
  ListItemText,
  Button,
  Divider,
  Drawer,
  useTheme,
  Collapse,
  ListItemIcon,
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { useLocation } from 'react-router-dom'
import { NavItem } from 'layouts/navigation'

interface MobileMenuProps {
  handleLogout: () => void
  navigate: (path: string) => void
  navItems: NavItem[]
}

function MobileMenu({ handleLogout, navigate, navItems }: MobileMenuProps) {
  const theme = useTheme()
  const [mobileOpen, setMobileOpen] = useState(false)
  const location = useLocation()
  const [openDropdowns, setOpenDropdowns] = useState<{ [key: string]: boolean }>({})

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const handleToggleDropdown = (title: string) => {
    setOpenDropdowns(prevState => ({
      ...prevState,
      [title]: !prevState[title],
    }))
  }

  const handleNavigate = (path: string | undefined) => {
    return () => {
      if (path) {
        navigate(path)
        setMobileOpen(false) // Close drawer after navigation
      }
    }
  }

  return (
    <>
      <Box
        alignItems={'center'}
        sx={{
          display: { xs: 'flex', md: 'none' },
          backgroundColor: theme.palette.primary.light,
          borderRadius: '8px',
          marginRight: 2,
        }}
      >
        <Button
          aria-label='Menu'
          variant={'outlined'}
          onClick={handleDrawerToggle}
          sx={{
            borderRadius: 2,
            minWidth: 'auto',
            padding: 1,
            color: 'black',
          }}
        >
          <MenuIcon />
        </Button>
      </Box>
      <Drawer
        anchor={'right'}
        open={mobileOpen}
        onClose={handleDrawerToggle}
        sx={{
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: 250,
            borderRadius: '8px',
            color: theme.palette.text.primary,
          },
        }}
      >
        <Box sx={{ width: 250 }}>
          <List>
            {navItems.map((page, index) =>
              'submenu' in page ? (
                <React.Fragment key={index}>
                  <ListItemButton
                    onClick={() => handleToggleDropdown(page.title)}
                    sx={{
                      backgroundColor:
                        location.pathname === page.path
                          ? theme.palette.secondary.main
                          : theme.palette.primary.contrastText,
                    }}
                  >
                    {page.icon && <ListItemIcon>{<page.icon />}</ListItemIcon>}
                    <ListItemText
                      primary={page.title}
                      sx={{
                        fontWeight: location.pathname === page.path ? 'bold' : 'normal',
                      }}
                    />
                    {openDropdowns[page.title] ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={openDropdowns[page.title]} timeout='auto' unmountOnExit>
                    <List component='div' disablePadding>
                      {page.submenu?.map((submenuItem, subIndex) => (
                        <ListItemButton
                          key={subIndex}
                          onClick={handleNavigate(submenuItem.path)}
                          sx={{
                            pl: 4,
                            backgroundColor:
                              location.pathname === submenuItem.path
                                ? theme.palette.secondary.main
                                : theme.palette.primary.contrastText,
                          }}
                        >
                          {submenuItem.icon && <ListItemIcon>{<submenuItem.icon />}</ListItemIcon>}
                          <ListItemText
                            primary={submenuItem.title}
                            sx={{
                              fontWeight: location.pathname === submenuItem.path ? 'bold' : 'normal',
                            }}
                          />
                        </ListItemButton>
                      ))}
                    </List>
                  </Collapse>
                </React.Fragment>
              ) : (
                <ListItemButton
                  key={index}
                  onClick={handleNavigate(page.path)}
                  sx={{
                    backgroundColor:
                      location.pathname === page.path
                        ? theme.palette.secondary.main
                        : theme.palette.primary.contrastText,
                  }}
                >
                  {page.icon && <ListItemIcon>{<page.icon />}</ListItemIcon>}
                  <ListItemText
                    primary={page.title}
                    sx={{
                      fontWeight: location.pathname === page.path ? 'bold' : 'normal',
                    }}
                  />
                </ListItemButton>
              ),
            )}
          </List>
          <Divider />
          <ListItemButton onClick={handleLogout}>Sign Out</ListItemButton>
        </Box>
      </Drawer>
    </>
  )
}

export default MobileMenu
