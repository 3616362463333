import React, { useState } from 'react'
import {
  Grid,
  TextField,
  Box,
  Typography,
  Button,
  CircularProgress,
  Backdrop,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  SelectChangeEvent,
} from '@mui/material'
import { createHubspotTicket, pullHubspotTickets } from 'core/api/hubspot'

// Define the ticket status dictionary for the different stages of the ticket pipeline
export const ticket_status: { [key: number]: string } = {
  1: 'New',
  2: 'Waiting on contact',
  3: 'Waiting on us',
  4: 'Closed',
}

// Define the props interface for the CreateTicketForm component
interface CreateTicketFormProps {
  handleClose: () => void // Function to handle closing the form
  setErrorCreatingTicket: React.Dispatch<React.SetStateAction<string | null>> // Function to set the error message state
  errorCreatingTicket: string | null // Error message state
}

// CreateTicketForm component definition
const CreateTicketForm: React.FC<CreateTicketFormProps> = ({
  handleClose,
  setErrorCreatingTicket,
  errorCreatingTicket,
}) => {
  // State for holding the form input values
  const [ticketData, setTicketData] = useState({
    subject: '',
    content: '',

    hs_pipeline_stage: '',
  })

  // State to manage the loading indicator
  const [loading, setLoading] = useState(false)

  // Handler for text field changes
  const handleTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setTicketData(prevState => ({ ...prevState, [name]: value }))
  }

  // Handler for select field changes
  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target
    setTicketData(prevState => ({ ...prevState, [name]: value }))
  }

  // Handler for form submission
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    try {
      // Set loading state to true to show the loading indicator
      setLoading(true)

      // Transform the form data into the required format for the HubSpot API
      const transformedData = [
        { name: 'subject', value: ticketData.subject }, // Lead Name
        { name: 'content', value: ticketData.content }, // Notes
        { name: 'hs_pipeline', value: '0' }, // Always set to 'Support Pipeline', internal value 0
        { name: 'hs_pipeline_stage', value: ticketData.hs_pipeline_stage }, // ticket status dictionary
      ]

      // Call the function to create a HubSpot ticket
      const response = await createHubspotTicket(transformedData)
      if (!response) {
        setErrorCreatingTicket('Error creating ticket.')
        setLoading(false)
        return
      }

      // Pull the updated tickets after creating the new ticket
      try {
        await pullHubspotTickets()
      } catch (error) {
        console.error('Error pulling tickets', error)
        setErrorCreatingTicket('Write something specific here')
      }

      // Show success message and close the form
      window.alert('New ticket successfully created!')
      handleClose()
    } catch (error) {
      console.error('Error creating ticket', error)
      setErrorCreatingTicket('Write something specific here')
    } finally {
      // Set loading state to false to hide the loading indicator
      setLoading(false)
    }
  }

  return (
    <Box component='form' noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
      <Grid container spacing={1}>
        {/* Ticket Subject Input Field */}
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id='subject'
            label='Lead Title'
            name='subject'
            autoComplete='subject'
            value={ticketData.subject}
            onChange={handleTextFieldChange}
          />
        </Grid>

        {/* Ticket Description Input Field */}
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id='content'
            label='Lead Notes'
            name='content'
            autoComplete='content'
            value={ticketData.content}
            onChange={handleTextFieldChange}
          />
        </Grid>

        {/* Pipeline Stage Select Field */}
        <Grid item xs={12}>
          <FormControl fullWidth required>
            <InputLabel id='hs_pipeline_stage-label'>Status</InputLabel>
            <Select
              labelId='hs_pipeline_stage-label'
              id='hs_pipeline_stage'
              name='hs_pipeline_stage'
              value={ticketData.hs_pipeline_stage}
              onChange={handleSelectChange}
              label='Pipeline Stage'
            >
              {Object.keys(ticket_status).map(key => (
                <MenuItem key={key} value={key}>
                  {ticket_status[parseInt(key)]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Display error message if any */}
        <Typography sx={{ color: 'red' }}>{errorCreatingTicket && `${errorCreatingTicket}`}</Typography>

        {/* Submit Button */}
        <Button type='submit' fullWidth variant='contained' sx={{ mt: 3, mb: 2 }}>
          Create Ticket
        </Button>
      </Grid>

      {/* Loading Indicator */}
      <Backdrop open={loading} style={{ zIndex: 1 }}>
        <CircularProgress color='inherit' />
      </Backdrop>
    </Box>
  )
}

export default CreateTicketForm
