import React, { useState, useEffect } from 'react'
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button } from '@mui/material'
import { adminFunctions } from 'core/api/application/admin' // Replace with actual import path

interface Application {
  application_id: string
  document_uuid: string
  user_email: string
  time_created: string
  status: string
  notes: string
  bank_agent: string
  bank_name: string
}

interface AddNotesDialogProps {
  application: Application | null
  isOpen: boolean
  onClose: () => void
}

const AddNotesDialog: React.FC<AddNotesDialogProps> = ({ application, isOpen, onClose }) => {
  const [notes, setNotes] = useState('')

  useEffect(() => {
    if (application) {
      setNotes(application.notes)
    }
  }, [application])

  const handleSave = async () => {
    if (application) {
      await adminFunctions.addNotesToApplication(application.application_id, notes)
    }
    onClose()
    setNotes('')
  }

  return (
    <Dialog fullWidth open={isOpen} onClose={onClose}>
      <DialogTitle>Add Notes</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          label='Application ID'
          margin='dense'
          value={application?.application_id || ''}
          InputProps={{
            readOnly: true,
            style: { backgroundColor: '#f5f5f5' },
          }}
          variant='outlined'
        />
        <TextField
          fullWidth
          label='Client Email'
          margin='dense'
          value={application?.user_email || ''}
          InputProps={{
            readOnly: true,
            style: { backgroundColor: '#f5f5f5' },
          }}
          variant='outlined'
        />
        <TextField
          fullWidth
          label='Time Created'
          margin='dense'
          value={application?.time_created || ''}
          InputProps={{
            readOnly: true,
            style: { backgroundColor: '#f5f5f5' },
          }}
          variant='outlined'
        />
        <TextField
          fullWidth
          label='Status'
          margin='dense'
          value={application?.status || ''}
          InputProps={{
            readOnly: true,
            style: { backgroundColor: '#f5f5f5' },
          }}
          variant='outlined'
        />
        <TextField
          fullWidth
          label='Bank Agent'
          margin='dense'
          value={application?.bank_agent || ''}
          InputProps={{
            readOnly: true,
            style: { backgroundColor: '#f5f5f5' },
          }}
          variant='outlined'
        />
        <TextField
          fullWidth
          label='Bank Name'
          margin='dense'
          value={application?.bank_name || ''}
          InputProps={{
            readOnly: true,
            style: { backgroundColor: '#f5f5f5' },
          }}
          variant='outlined'
        />
        <TextField
          fullWidth
          label='Notes'
          multiline
          rows={4}
          margin='dense'
          value={notes}
          onChange={e => setNotes(e.target.value)}
          variant='outlined'
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave}>Save</Button>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddNotesDialog
