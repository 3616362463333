import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button } from '@mui/material'
import { createLead } from 'core/api/application/client'

interface CreateLeadDialogProps {
  open: boolean
  onClose: () => void
}

const CreateLeadDialog = ({ open, onClose }: CreateLeadDialogProps) => {
  const [formData, setFormData] = useState<Lead>({
    id: undefined,
    name: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    state: '',
    information: '',
    number_of_trucks: '',
    type: '',
    value: '',
    status: '',
  })

  const handleChange = (event: { target: { name: string; value: string } }) => {
    setFormData({ ...formData, [event.target.name]: event.target.value })
  }

  const handleSubmit = async () => {
    createLead(formData)
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Create New Lead</DialogTitle>
      <DialogContent>
        <form id='create-lead-form' onSubmit={handleSubmit}>
          <TextField
            label='Name'
            name='name'
            fullWidth
            margin='normal'
            onChange={handleChange}
            value={formData.name}
          />
          <TextField
            label='Email'
            name='email'
            fullWidth
            margin='normal'
            onChange={handleChange}
            value={formData.email}
          />
          <TextField
            label='Phone'
            name='phone'
            fullWidth
            margin='normal'
            onChange={handleChange}
            value={formData.phone}
          />
          <TextField
            label='Address'
            name='address'
            fullWidth
            margin='normal'
            onChange={handleChange}
            value={formData.address}
          />
          <TextField
            label='City'
            name='city'
            fullWidth
            margin='normal'
            onChange={handleChange}
            value={formData.city}
          />
          <TextField
            label='State'
            name='state'
            fullWidth
            margin='normal'
            onChange={handleChange}
            value={formData.state}
          />
          <TextField
            label='Information'
            name='information'
            fullWidth
            margin='normal'
            onChange={handleChange}
            value={formData.information}
            multiline
          />
          <TextField
            label='Number of Trucks'
            name='numberOfTrucks'
            fullWidth
            margin='normal'
            onChange={handleChange}
            value={formData.number_of_trucks}
            type='number'
          />
          <TextField
            label='Type'
            name='type'
            fullWidth
            margin='normal'
            onChange={handleChange}
            value={formData.type}
          />
          <TextField
            label='Value'
            name='value'
            fullWidth
            margin='normal'
            onChange={handleChange}
            value={formData.value}
            type='number'
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button type='submit' form='create-lead-form'>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CreateLeadDialog
