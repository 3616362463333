import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from 'contexts/AuthContext'
import { IsLoading, NotFound } from 'components'

interface ClientRouteProps {
  children: React.ReactNode
}

const useClientRoute = () => {
  const { isAuthenticated, userInfo, isLoading } = useAuth()
  const navigate = useNavigate()
  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login')
    } else if ((userInfo && userInfo.user_type !== 'client') || !userInfo?.user_type) {
      navigate(`/not-found`)
    }
  }, [isAuthenticated, navigate])

  return { isAuthenticated, isLoading }
}

const ClientRoute = ({ children }: ClientRouteProps) => {
  const { isAuthenticated, isLoading } = useClientRoute()

  if (isLoading) {
    return <IsLoading />
  }

  if (!isAuthenticated) {
    return <NotFound />
  }

  return <>{children}</>
}

export default ClientRoute
