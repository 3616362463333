import { collection } from '@firebase/firestore'
import { useFirestoreCollectionMutation } from '@react-query-firebase/firestore'
import { useSnackbar } from 'contexts/snackBarContext'
import { db } from 'core/config/firebase'
import { UseMutationResult } from 'react-query'

interface AddFirestoreDocumentProps {
  collectionName: string
  useMutationResult?: UseMutationResult
}

export const useAddFirestoreDocument = ({ collectionName, useMutationResult }: AddFirestoreDocumentProps) => {
  const { showSnackbar } = useSnackbar()

  return useFirestoreCollectionMutation(collection(db, collectionName), {
    ...useMutationResult,
    onError: error => {
      showSnackbar(`Adding document to ${collectionName} failed`, 'error')
      console.error(error)
    },
  })
}
