import { Box, Card, CardContent, CardHeader, CardProps, Typography } from '@mui/material'
import { RFISubmission } from 'core/types/form_submission'
import React from 'react'

interface SubmissionFormProps extends CardProps {
  submission: RFISubmission
  onClick: () => void
}

const RFISubmissionCard: React.FC<SubmissionFormProps> = ({ submission, onClick, ...cardProps }) => {
  return (
    <Card {...cardProps} sx={{ minWidth: 275 }}>
      <CardHeader title={`${submission.firstName} ${submission.lastName}`} sx={{ pb: 1 }} />
      <CardContent onClick={onClick}>
        <Box display='flex' flexDirection='column' mt={1} gap={3}>
          {submission.customerType && (
            <Typography>
              <strong>Customer type:</strong> {submission.customerType}{' '}
              {submission.commercialPurpose ? `- ${submission.commercialPurpose}` : ''}
            </Typography>
          )}
          <Box>
            <Typography fontWeight='medium' variant='h5' sx={{ mb: 1 }}>
              Vehicles of Interest
            </Typography>
            <Box ml={2} display='flex' flexDirection='column' gap={1}>
              {submission.selectedVehicles && submission.selectedVehicles.length > 0 ? (
                submission.selectedVehicles.map((vehicle, index) => (
                  <Typography key={index}>
                    <strong>{index + 1}.</strong>{' '}
                    <Typography
                      component='a'
                      href={vehicle.link}
                      target='_blank'
                      sx={{
                        textDecoration: 'underline',
                        '&:hover': {
                          textDecoration: 'underline',
                          color: 'blue',
                        },
                      }}
                    >
                      {vehicle.name + (vehicle.quantity && vehicle.quantity > 1 ? ` (${vehicle.quantity})` : '')}
                    </Typography>
                    <Typography ml={2}>{vehicle.stockNumber}</Typography>
                  </Typography>
                ))
              ) : (
                <Typography>No vehicles selected</Typography>
              )}
            </Box>
          </Box>
          <Box>
            <Typography fontWeight='medium' variant='h5' sx={{ mb: 1 }}>
              Contact Information
            </Typography>
            <Box ml={2} display='flex' flexDirection='column' gap={1}>
              <Typography>
                <strong>Email:</strong> {submission.email}
              </Typography>
              <Typography>
                <strong>Phone:</strong> {submission.phone}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Typography fontWeight='medium' variant='h5' sx={{ mb: 1 }}>
              Company Information
            </Typography>
            <Box ml={2} display='flex' flexDirection='column' gap={1}>
              <Typography>
                <strong>Company:</strong> {submission.companyName}
              </Typography>
              {submission.companyPosition && (
                <Typography>
                  <strong>Position:</strong> {submission.companyPosition}
                </Typography>
              )}
              {submission.companyLocation && (
                <Typography>
                  <strong>Location:</strong> {submission.companyLocation}
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}

export default RFISubmissionCard
