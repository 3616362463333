import { AdminProvider } from 'contexts/AdminContext'
import { SnackbarProvider } from 'contexts/snackBarContext'
import * as React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { AuthProvider } from './contexts/AuthContext'
import { AppRoutes } from './routes/AppRoutes'

const queryClient = new QueryClient()

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <AdminProvider>
          <SnackbarProvider>
            <AppRoutes />
          </SnackbarProvider>
        </AdminProvider>
      </AuthProvider>
    </QueryClientProvider>
  )
}
