/* istanbul ignore file */


// Import the functions you need from the SDKs you need
import { getAuth } from '@firebase/auth'
import { getFirestore } from '@firebase/firestore';
import { getStorage } from '@firebase/storage';
import { initializeApp } from 'firebase/app'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

// const firebaseConfig = {
//   apiKey: 'AIzaSyAjMrO1o1Yp9721nlqGXRHiou6sBUc62gI',
//   authDomain: 'otw-production-91ffb.firebaseapp.com',
//   projectId: 'otw-production-91ffb',
//   storageBucket: 'otw-production-91ffb.appspot.com',
//   messagingSenderId: '250413884321',
//   appId: '1:250413884321:web:ba2a830f5cf950252aa1e0',
// }

// Initialize Firebase
const app = initializeApp(firebaseConfig ? firebaseConfig : {})

const auth = getAuth(app)
const db = getFirestore(app);
const storage = getStorage(app)

export { auth, db, app, firebaseConfig, storage }
