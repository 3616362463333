import React, { useEffect, useState } from 'react'
import { Grid, Card, CardContent, Typography } from '@mui/material'
import { getLeads } from 'core/api/application/client'

const LeadTable = () => {
  const [leads, setLeads] = useState<Lead[]>([])

  useEffect(() => {
    const fetchLeads = async () => {
      try {
        const response = await getLeads()
        const data = await response.json() // Await the conversion to JSON
        setLeads(data)
      } catch (error) {
        console.error('Failed to fetch leads', error)
      }
    }

    fetchLeads()
  }, [])

  return (
    <>
      <Typography variant='h4' component='h1'>
        Submitted Leads
      </Typography>
      <Grid container spacing={2}>
        {leads.map((lead: Lead) => (
          <Grid item xs={12} sm={6} md={4} key={lead.id}>
            <Card>
              <CardContent>
                <Typography variant='h5' component='h2'>
                  {lead.name}
                </Typography>
                <Typography color='textSecondary'>{lead.email}</Typography>
                {/* Add more fields as needed */}
                <Typography variant='body2' component='p'>
                  Phone: {lead.phone}
                </Typography>
                <Typography variant='body2' component='p'>
                  Address: {lead.address}, {lead.city}, {lead.state}
                </Typography>
                <Typography variant='body2' component='p'>
                  Information: {lead.information}
                </Typography>
                <Typography variant='body2' component='p'>
                  Trucks: {lead.number_of_trucks}
                </Typography>
                <Typography variant='body2' component='p'>
                  Type: {lead.type}
                </Typography>
                <Typography variant='body2' component='p'>
                  Value: {lead.value}
                </Typography>
                <Typography variant='body2' component='p'>
                  Status: {lead.status}
                </Typography>
                {/* <Typography variant="body2" component="p">
                                    Created: {lead.time_created}
                                </Typography> */}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  )
}

export default LeadTable
